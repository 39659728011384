(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.aY.aj === region.a7.aj)
	{
		return 'on line ' + region.aY.aj;
	}
	return 'on lines ' + region.aY.aj + ' through ' + region.a7.aj;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.b5,
		impl.cF,
		impl.cy,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		cb: func(record.cb),
		cw: record.cw,
		cm: record.cm
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.cb;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.cw;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.cm) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.b5,
		impl.cF,
		impl.cy,
		function(sendToApp, initialModel) {
			var view = impl.cH;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.b5,
		impl.cF,
		impl.cy,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.aX && impl.aX(sendToApp)
			var view = impl.cH;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.bP);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.cD) && (_VirtualDom_doc.title = title = doc.cD);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.cj;
	var onUrlRequest = impl.ck;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		aX: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.bt === next.bt
							&& curr.bf === next.bf
							&& curr.bp.a === next.bp.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		b5: function(flags)
		{
			return A3(impl.b5, flags, _Browser_getUrl(), key);
		},
		cH: impl.cH,
		cF: impl.cF,
		cy: impl.cy
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { b1: 'hidden', bT: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { b1: 'mozHidden', bT: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { b1: 'msHidden', bT: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { b1: 'webkitHidden', bT: 'webkitvisibilitychange' }
		: { b1: 'hidden', bT: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		bB: _Browser_getScene(),
		ar: {
			a0: _Browser_window.pageXOffset,
			cL: _Browser_window.pageYOffset,
			cJ: _Browser_doc.documentElement.clientWidth,
			b0: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		cJ: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		b0: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			bB: {
				cJ: node.scrollWidth,
				b0: node.scrollHeight
			},
			ar: {
				a0: node.scrollLeft,
				cL: node.scrollTop,
				cJ: node.clientWidth,
				b0: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			bB: _Browser_getScene(),
			ar: {
				a0: x,
				cL: y,
				cJ: _Browser_doc.documentElement.clientWidth,
				b0: _Browser_doc.documentElement.clientHeight
			},
			bW: {
				a0: x + rect.left,
				cL: y + rect.top,
				cJ: rect.width,
				b0: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.bX.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.bX.b, xhr)); });
		$elm$core$Maybe$isJust(request.bH) && _Http_track(router, xhr, request.bH.a);

		try {
			xhr.open(request.cc, request.cG, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.cG));
		}

		_Http_configureRequest(xhr, request);

		request.bP.a && xhr.setRequestHeader('Content-Type', request.bP.a);
		xhr.send(request.bP.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.bd; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.cC.a || 0;
	xhr.responseType = request.bX.d;
	xhr.withCredentials = request.bM;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		cG: xhr.responseURL,
		cu: xhr.status,
		cv: xhr.statusText,
		bd: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			cs: event.loaded,
			bD: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			cn: event.loaded,
			bD: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}



// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.i) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.l),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.l);
		} else {
			var treeLen = builder.i * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.m) : builder.m;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.i);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.l) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.l);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{m: nodeList, i: (len / $elm$core$Array$branchFactor) | 0, l: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Search$Main$Flags = F7(
	function (appliedFilterCount, date, initialPath, lengthsOfStay, location, page, venue) {
		return {ab: appliedFilterCount, r: date, aI: initialPath, x: lengthsOfStay, g: location, aQ: page, aZ: venue};
	});
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $author$project$SearchDatepicker$Flexible = function (a) {
	return {$: 0, a: a};
};
var $author$project$SearchDatepicker$NoDate = {$: 2};
var $author$project$Shared$Decoders$decodeServerSideMonad = F2(
	function (maybeContents, maybeError) {
		if (!maybeError.$) {
			return $elm$core$Maybe$Nothing;
		} else {
			return maybeContents;
		}
	});
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $elm$json$Json$Decode$map2 = _Json_map2;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (path, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2(
				$elm$json$Json$Decode$decodeValue,
				A2($elm$json$Json$Decode$at, path, $elm$json$Json$Decode$value),
				input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					return A2(
						$elm$json$Json$Decode$at,
						path,
						nullOr(valDecoder));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				_List_fromArray(
					[key]),
				valDecoder,
				fallback),
			decoder);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Shared$Decoders$decodeDryMonad = function (decoder) {
	return A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'trace',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'value',
			$elm$json$Json$Decode$maybe(decoder),
			$elm$core$Maybe$Nothing,
			$elm$json$Json$Decode$succeed($author$project$Shared$Decoders$decodeServerSideMonad)));
};
var $author$project$SearchDatepicker$FlexibleDate = 1;
var $author$project$SearchDatepicker$MultiDate = function (a) {
	return {$: 1, a: a};
};
var $author$project$SearchDatepicker$SingleDate = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$SearchDatepicker$SpecificDate = 0;
var $author$project$SearchDatepicker$Weekdays = 1;
var $author$project$SearchDatepicker$Weekends = 0;
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$SearchDatepicker$decodeFlexibility = F2(
	function (date, maybeInt) {
		if (!maybeInt.$) {
			switch (maybeInt.a) {
				case 1:
					return $elm$json$Json$Decode$succeed(
						_Utils_Tuple2(
							date,
							$author$project$SearchDatepicker$Flexible(
								A2($author$project$SearchDatepicker$SingleDate, date, 1))));
				case 2:
					return $elm$json$Json$Decode$succeed(
						_Utils_Tuple2(
							date,
							$author$project$SearchDatepicker$Flexible(
								$author$project$SearchDatepicker$MultiDate(1))));
				case 3:
					return $elm$json$Json$Decode$succeed(
						_Utils_Tuple2(
							date,
							$author$project$SearchDatepicker$Flexible(
								$author$project$SearchDatepicker$MultiDate(0))));
				default:
					return $elm$json$Json$Decode$fail('Error decoding date flexibility');
			}
		} else {
			return $elm$json$Json$Decode$succeed(
				_Utils_Tuple2(
					date,
					$author$project$SearchDatepicker$Flexible(
						A2($author$project$SearchDatepicker$SingleDate, date, 0))));
		}
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$core$Basics$neq = _Utils_notEqual;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.aY, posixMinutes) < 0) {
					return posixMinutes + era.b;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		a5: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		bk: month,
		bL: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).a5;
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).bk;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).bL;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Shared$Decoders$parseMillisDate = A2(
	$elm$core$Basics$composeR,
	$elm$time$Time$millisToPosix,
	$justinmimbs$date$Date$fromPosix($elm$time$Time$utc));
var $author$project$Shared$Decoders$decodeMillisDate = A2($elm$json$Json$Decode$map, $author$project$Shared$Decoders$parseMillisDate, $elm$json$Json$Decode$int);
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$resolve = $elm$json$Json$Decode$andThen($elm$core$Basics$identity);
var $author$project$SearchDatepicker$dateDecoder = $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$resolve(
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'flexibility',
		$elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
					$author$project$Shared$Decoders$decodeDryMonad($elm$json$Json$Decode$int)
				])),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'selected',
			$author$project$Shared$Decoders$decodeMillisDate,
			$elm$json$Json$Decode$succeed($author$project$SearchDatepicker$decodeFlexibility))));
var $author$project$SearchDatepicker$initDate = A2(
	$justinmimbs$date$Date$fromPosix,
	$elm$time$Time$utc,
	$elm$time$Time$millisToPosix(0));
var $author$project$SearchDatepicker$decodeDateSelection = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			$author$project$SearchDatepicker$dateDecoder,
			$elm$json$Json$Decode$succeed(
			_Utils_Tuple2(
				$author$project$SearchDatepicker$initDate,
				$author$project$SearchDatepicker$Flexible($author$project$SearchDatepicker$NoDate)))
		]));
var $author$project$Search$Main$Error = 3;
var $author$project$Search$Main$Home = 0;
var $author$project$Search$Main$Search = 1;
var $author$project$Search$Main$Venue = 2;
var $author$project$Search$Main$decodePage = function (page) {
	switch (page) {
		case 'home':
			return $elm$json$Json$Decode$succeed(0);
		case 'search':
			return $elm$json$Json$Decode$succeed(1);
		case 'venue':
			return $elm$json$Json$Decode$succeed(2);
		case 'error':
			return $elm$json$Json$Decode$succeed(3);
		default:
			return $elm$json$Json$Decode$fail('Unknown page');
	}
};
var $miyamoen$select_list$Types$SelectList = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $miyamoen$select_list$Types$fromLists = F3(
	function (before, a, after) {
		return A3(
			$miyamoen$select_list$Types$SelectList,
			$elm$core$List$reverse(before),
			a,
			after);
	});
var $miyamoen$select_list$SelectList$fromLists = $miyamoen$select_list$Types$fromLists;
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Shared$Decoders$decodeSelectList = function (decoder) {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'after',
		$elm$json$Json$Decode$list(decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'selected',
			decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'before',
				$elm$json$Json$Decode$list(decoder),
				$elm$json$Json$Decode$succeed($miyamoen$select_list$SelectList$fromLists))));
};
var $elm$json$Json$Decode$index = _Json_decodeIndex;
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $author$project$Shared$Decoders$decodeStringTuple = A3(
	$elm$json$Json$Decode$map2,
	$elm$core$Tuple$pair,
	A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$index, 1, $elm$json$Json$Decode$string));
var $author$project$Search$Main$Dropdown = function (a) {
	return {$: 0, a: a};
};
var $author$project$Search$Main$Suggest = function (a) {
	return {$: 1, a: a};
};
var $author$project$Search$Main$AutocompleteBlur = {$: 5};
var $author$project$Search$Main$AutocompleteChanged = function (a) {
	return {$: 0, a: a};
};
var $author$project$Search$Main$AutocompleteFocus = function (a) {
	return {$: 6, a: a};
};
var $author$project$Search$Main$AutocompleteHover = function (a) {
	return {$: 3, a: a};
};
var $author$project$Search$Main$AutocompleteMessage = function (a) {
	return {$: 0, a: a};
};
var $author$project$Search$Main$AutocompleteScroll = function (a) {
	return {$: 2, a: a};
};
var $author$project$Search$Main$AutocompleteSelect = function (a) {
	return {$: 4, a: a};
};
var $author$project$Search$Main$NoOp = {$: 8};
var $author$project$Search$Main$ReceivedResults = function (a) {
	return {$: 7, a: a};
};
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $author$project$Shared$Input$Input = $elm$core$Basics$identity;
var $author$project$Shared$Input$notRequired = function (_v0) {
	var attributes = _v0;
	return _Utils_update(
		attributes,
		{D: false});
};
var $author$project$Shared$Input$withIcon = F2(
	function (icon, _v0) {
		var attributes = _v0;
		return _Utils_update(
			attributes,
			{
				N: $elm$core$Maybe$Just(icon)
			});
	});
var $author$project$Shared$Input$withClass = F2(
	function (_class, _v0) {
		var attributes = _v0;
		return _Utils_update(
			attributes,
			{
				y: A2(
					$elm$core$List$cons,
					_Utils_Tuple2(_class, true),
					attributes.y)
			});
	});
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $author$project$Shared$Input$withHtmlAttrs = F2(
	function (htmlAttrs, _v0) {
		var attributes = _v0;
		return _Utils_update(
			attributes,
			{
				k: A2(
					$elm$core$Dict$union,
					$elm$core$Dict$fromList(htmlAttrs),
					attributes.k)
			});
	});
var $author$project$Shared$Input$withPlaceholderLabel = F2(
	function (placeholder, input) {
		return A2(
			$author$project$Shared$Input$withHtmlAttrs,
			_List_fromArray(
				[
					_Utils_Tuple2('placeholder', placeholder)
				]),
			A2($author$project$Shared$Input$withClass, 'variant:placeholder-label', input));
	});
var $author$project$Search$Main$autocompleteInputConfig = A2(
	$elm$core$Basics$composeR,
	$author$project$Shared$Input$withPlaceholderLabel('Postcode, spa, region, or town...'),
	A2(
		$elm$core$Basics$composeR,
		$author$project$Shared$Input$withIcon('fa-map-marker-alt fa-fw'),
		$author$project$Shared$Input$notRequired));
var $author$project$Autocomplete$Autocomplete = $elm$core$Basics$identity;
var $author$project$Autocomplete$emptyLocations = {aT: _List_Nil, a_: _List_Nil};
var $author$project$Shared$Input$text = F2(
	function (labelText, inputHandler) {
		return {
			y: _List_Nil,
			F: false,
			o: '',
			H: '',
			k: $elm$core$Dict$fromList(
				_List_fromArray(
					[
						_Utils_Tuple2('type', 'text'),
						_Utils_Tuple2('maxlength', '64')
					])),
			N: $elm$core$Maybe$Nothing,
			I: labelText,
			cf: $elm$core$Maybe$Nothing,
			W: $elm$core$Maybe$Nothing,
			cg: $elm$core$Maybe$Nothing,
			K: inputHandler,
			ch: $elm$core$Maybe$Nothing,
			P: $elm$core$Maybe$Nothing,
			D: true,
			j: ''
		};
	});
var $author$project$Shared$Input$withAutocomplete = F2(
	function (autocompleteVal, _v0) {
		var attributes = _v0;
		return _Utils_update(
			attributes,
			{
				k: A3($elm$core$Dict$insert, 'autocomplete', autocompleteVal, attributes.k)
			});
	});
var $author$project$Shared$Input$withBlur = F2(
	function (callback, _v0) {
		var attributes = _v0;
		return _Utils_update(
			attributes,
			{
				cf: $elm$core$Maybe$Just(callback)
			});
	});
var $author$project$Shared$Input$withFocus = F2(
	function (callback, _v0) {
		var attributes = _v0;
		return _Utils_update(
			attributes,
			{
				cg: $elm$core$Maybe$Just(callback)
			});
	});
var $author$project$Shared$Input$withKeyDown = F2(
	function (callback, _v0) {
		var attributes = _v0;
		return _Utils_update(
			attributes,
			{
				ch: $elm$core$Maybe$Just(callback)
			});
	});
var $author$project$Autocomplete$init = function (_v0) {
	var textChanged = _v0.cB;
	var listBoxId = _v0.aJ;
	var onKeyDown = _v0.ch;
	var onHover = _v0.ad;
	var onSelect = _v0.ae;
	var onBlur = _v0.cf;
	var onFocus = _v0.cg;
	var receivedResults = _v0.aS;
	var inputConfig = _v0.b6;
	return {
		G: $elm$core$Maybe$Nothing,
		z: inputConfig(
			A2(
				$author$project$Shared$Input$withHtmlAttrs,
				_List_fromArray(
					[
						_Utils_Tuple2('role', 'combobox'),
						_Utils_Tuple2('aria-autocomplete', 'list'),
						_Utils_Tuple2('aria-expanded', 'false'),
						_Utils_Tuple2('aria-owns', listBoxId)
					]),
				A2(
					$author$project$Shared$Input$withFocus,
					onFocus,
					A2(
						$author$project$Shared$Input$withBlur,
						onBlur,
						A2(
							$author$project$Shared$Input$withKeyDown,
							onKeyDown,
							A2(
								$author$project$Shared$Input$withAutocomplete,
								'off',
								A2($author$project$Shared$Input$text, 'Where', textChanged))))))),
		aJ: listBoxId,
		av: $author$project$Autocomplete$emptyLocations,
		ad: onHover,
		ae: onSelect,
		aR: _List_Nil,
		aS: receivedResults,
		af: _List_Nil,
		Z: $elm$core$Maybe$Nothing
	};
};
var $author$project$Search$Main$initAutocomplete = $author$project$Autocomplete$init(
	{
		b6: $author$project$Search$Main$autocompleteInputConfig,
		aJ: 'location-autocomplete-results',
		cf: A2(
			$elm$core$Basics$composeR,
			$elm$core$Basics$always($author$project$Search$Main$AutocompleteBlur),
			$author$project$Search$Main$AutocompleteMessage),
		cg: A2($elm$core$Basics$composeR, $author$project$Search$Main$AutocompleteFocus, $author$project$Search$Main$AutocompleteMessage),
		ad: A2($elm$core$Basics$composeR, $author$project$Search$Main$AutocompleteHover, $author$project$Search$Main$AutocompleteMessage),
		ch: A2($elm$core$Basics$composeR, $author$project$Search$Main$AutocompleteScroll, $author$project$Search$Main$AutocompleteMessage),
		ae: A2($elm$core$Basics$composeR, $author$project$Search$Main$AutocompleteSelect, $author$project$Search$Main$AutocompleteMessage),
		aS: {
			bm: $author$project$Search$Main$AutocompleteMessage($author$project$Search$Main$NoOp),
			bn: A2($elm$core$Basics$composeR, $author$project$Search$Main$ReceivedResults, $author$project$Search$Main$AutocompleteMessage)
		},
		cB: A2($elm$core$Basics$composeR, $author$project$Search$Main$AutocompleteChanged, $author$project$Search$Main$AutocompleteMessage)
	});
var $author$project$Search$Main$LocationBlur = {$: 0};
var $author$project$Search$Main$LocationChanged = function (a) {
	return {$: 2, a: a};
};
var $author$project$Search$Main$LocationFocus = function (a) {
	return {$: 1, a: a};
};
var $author$project$Search$Main$LocationHover = function (a) {
	return {$: 4, a: a};
};
var $author$project$Search$Main$LocationMessage = function (a) {
	return {$: 1, a: a};
};
var $author$project$Search$Main$LocationMouseDown = {$: 5};
var $author$project$Search$Main$LocationScroll = function (a) {
	return {$: 6, a: a};
};
var $author$project$Search$Main$LocationSelect = {$: 7};
var $author$project$LocationFilter$Any = {$: 0};
var $author$project$LocationFilter$Closed = 1;
var $author$project$LocationFilter$LocationFilter = $elm$core$Basics$identity;
var $miyamoen$select_list$Types$listBefore = function (_v0) {
	var xs = _v0.a;
	return $elm$core$List$reverse(xs);
};
var $miyamoen$select_list$SelectList$listBefore = $miyamoen$select_list$Types$listBefore;
var $miyamoen$select_list$Transform$updateBefore = F2(
	function (f, _v0) {
		var before = _v0.a;
		var a = _v0.b;
		var after = _v0.c;
		return A3(
			$miyamoen$select_list$Types$SelectList,
			$elm$core$List$reverse(
				f(
					$elm$core$List$reverse(before))),
			a,
			after);
	});
var $miyamoen$select_list$SelectList$replaceBefore = function (xs) {
	return $miyamoen$select_list$Transform$updateBefore(
		$elm$core$Basics$always(xs));
};
var $author$project$LocationFilter$resultName = function (resultType) {
	switch (resultType.$) {
		case 0:
			return 'Any destination';
		case 1:
			var result = resultType.a;
			return result.A;
		case 2:
			var result = resultType.a;
			return result.A;
		default:
			var result = resultType.a;
			return result.A;
	}
};
var $miyamoen$select_list$Types$selected = function (_v0) {
	var a = _v0.b;
	return a;
};
var $miyamoen$select_list$SelectList$selected = $miyamoen$select_list$Types$selected;
var $author$project$Shared$Input$withMouseUp = F2(
	function (callback, _v0) {
		var attributes = _v0;
		return _Utils_update(
			attributes,
			{
				P: $elm$core$Maybe$Just(callback)
			});
	});
var $author$project$Shared$Input$withValue = F2(
	function (value, _v0) {
		var attributes = _v0;
		return _Utils_update(
			attributes,
			{j: value});
	});
var $author$project$LocationFilter$init = function (config) {
	var results = A2(
		$miyamoen$select_list$SelectList$replaceBefore,
		A2(
			$elm$core$List$cons,
			$author$project$LocationFilter$Any,
			$miyamoen$select_list$SelectList$listBefore(config.af)),
		config.af);
	return {
		G: 0,
		z: config.b6(
			A2(
				$author$project$Shared$Input$withValue,
				$author$project$LocationFilter$resultName(
					$miyamoen$select_list$SelectList$selected(results)),
				A2(
					$author$project$Shared$Input$withHtmlAttrs,
					_List_fromArray(
						[
							_Utils_Tuple2('role', 'combobox'),
							_Utils_Tuple2('aria-autocomplete', 'list'),
							_Utils_Tuple2('aria-expanded', 'false'),
							_Utils_Tuple2('aria-owns', config.aJ)
						]),
					A2(
						$author$project$Shared$Input$withMouseUp,
						config.ci,
						A2(
							$author$project$Shared$Input$withFocus,
							config.cg,
							A2(
								$author$project$Shared$Input$withBlur,
								config.cf,
								A2(
									$author$project$Shared$Input$withKeyDown,
									config.ch,
									A2(
										$author$project$Shared$Input$withAutocomplete,
										'off',
										A2($author$project$Shared$Input$text, 'Where', config.cB))))))))),
		aJ: config.aJ,
		ad: config.ad,
		ae: config.ae,
		af: results,
		S: 1
	};
};
var $author$project$Search$Main$initLocationFilter = function (results) {
	return $elm$json$Json$Decode$succeed(
		$author$project$LocationFilter$init(
			{
				b6: $author$project$Search$Main$autocompleteInputConfig,
				aJ: 'location-autocomplete-results',
				cf: A2(
					$elm$core$Basics$composeR,
					$elm$core$Basics$always($author$project$Search$Main$LocationBlur),
					$author$project$Search$Main$LocationMessage),
				cg: A2($elm$core$Basics$composeR, $author$project$Search$Main$LocationFocus, $author$project$Search$Main$LocationMessage),
				ad: A2($elm$core$Basics$composeR, $author$project$Search$Main$LocationHover, $author$project$Search$Main$LocationMessage),
				ch: A2($elm$core$Basics$composeR, $author$project$Search$Main$LocationScroll, $author$project$Search$Main$LocationMessage),
				ci: $author$project$Search$Main$LocationMessage($author$project$Search$Main$LocationMouseDown),
				ae: $author$project$Search$Main$LocationMessage($author$project$Search$Main$LocationSelect),
				af: results,
				cB: A2($elm$core$Basics$composeR, $author$project$Search$Main$LocationChanged, $author$project$Search$Main$LocationMessage)
			}));
};
var $author$project$LocationFilter$Place = function (a) {
	return {$: 2, a: a};
};
var $author$project$LocationFilter$PlaceResult = F3(
	function (name, slug, distance) {
		return {aD: distance, A: name, am: slug};
	});
var $author$project$LocationFilter$placeResultDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$LocationFilter$Place,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'distance',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'slug',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'name',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$LocationFilter$PlaceResult)))));
var $author$project$LocationFilter$Region = function (a) {
	return {$: 1, a: a};
};
var $author$project$LocationFilter$RegionResult = F2(
	function (name, slug) {
		return {A: name, am: slug};
	});
var $author$project$LocationFilter$regionResultDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$LocationFilter$Region,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'slug',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$LocationFilter$RegionResult))));
var $author$project$LocationFilter$Venue = function (a) {
	return {$: 3, a: a};
};
var $author$project$LocationFilter$VenueResult = F2(
	function (name, slug) {
		return {A: name, am: slug};
	});
var $author$project$LocationFilter$venueDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$LocationFilter$Venue,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'slug',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$LocationFilter$VenueResult))));
var $author$project$LocationFilter$resultsDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			$author$project$Shared$Decoders$decodeSelectList($author$project$LocationFilter$placeResultDecoder),
			$author$project$Shared$Decoders$decodeSelectList($author$project$LocationFilter$regionResultDecoder),
			A2(
			$elm$json$Json$Decode$map,
			function (venue) {
				return A3($miyamoen$select_list$SelectList$fromLists, _List_Nil, venue, _List_Nil);
			},
			$author$project$LocationFilter$venueDecoder)
		]));
var $author$project$Search$Main$locationDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$map,
			$author$project$Search$Main$Dropdown,
			A2($elm$json$Json$Decode$andThen, $author$project$Search$Main$initLocationFilter, $author$project$LocationFilter$resultsDecoder)),
			A2(
			$elm$json$Json$Decode$map,
			$author$project$Search$Main$Suggest,
			$elm$json$Json$Decode$succeed($author$project$Search$Main$initAutocomplete))
		]));
var $author$project$Search$Main$flagsDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'venue',
	$elm$json$Json$Decode$nullable($author$project$Search$Main$locationDecoder),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'page',
		A2($elm$json$Json$Decode$andThen, $author$project$Search$Main$decodePage, $elm$json$Json$Decode$string),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'location',
			$author$project$Search$Main$locationDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'lengthsOfStay',
				$author$project$Shared$Decoders$decodeSelectList($author$project$Shared$Decoders$decodeStringTuple),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'path',
					$author$project$Shared$Decoders$decodeDryMonad($elm$json$Json$Decode$string),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'date',
						$author$project$SearchDatepicker$decodeDateSelection,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'appliedFilterCount',
							$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
							$elm$json$Json$Decode$succeed($author$project$Search$Main$Flags))))))));
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Search$Main$decodeFlags = function (flags) {
	return $elm$core$Result$toMaybe(
		A2($elm$json$Json$Decode$decodeValue, $author$project$Search$Main$flagsDecoder, flags));
};
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {bc: fragment, bf: host, ax: path, bp: port_, bt: protocol, bu: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $author$project$Utils$Browser$ErrorParsingFlags = {$: 0};
var $author$project$Utils$Browser$FlagsParsed = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $author$project$Utils$Browser$init = F2(
	function (appConfig, encodedFlags) {
		var _v0 = appConfig.bZ(encodedFlags);
		if (_v0.$ === 1) {
			return _Utils_Tuple2($author$project$Utils$Browser$ErrorParsingFlags, appConfig.bm);
		} else {
			var flags = _v0.a;
			return A2(
				$elm$core$Tuple$mapFirst,
				$author$project$Utils$Browser$FlagsParsed,
				appConfig.b5(flags));
		}
	});
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Utils$Browser$subscriptions = F2(
	function (appConfig, modelWrapper) {
		if (!modelWrapper.$) {
			return $elm$core$Platform$Sub$none;
		} else {
			var model = modelWrapper.a;
			return appConfig.cy(model);
		}
	});
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Utils$Browser$update = F3(
	function (appConfig, msg, modelWrapper) {
		if (!modelWrapper.$) {
			return _Utils_Tuple2(modelWrapper, $elm$core$Platform$Cmd$none);
		} else {
			var model = modelWrapper.a;
			return A2(
				$elm$core$Tuple$mapFirst,
				$author$project$Utils$Browser$FlagsParsed,
				A2(appConfig.cF, msg, model));
		}
	});
var $author$project$Shared$Views$NoticeWithText = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$aside = _VirtualDom_node('aside');
var $elm$json$Json$Encode$string = _Json_wrap;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$html$Html$header = _VirtualDom_node('header');
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$html$Html$i = _VirtualDom_node('i');
var $elm$core$Basics$not = _Basics_not;
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Utils$Html$viewIf = F2(
	function (condition, viewHtml) {
		return condition ? viewHtml : $elm$html$Html$text('');
	});
var $author$project$Shared$Icons$view = F2(
	function (styleClass, classNames) {
		return A2(
			$author$project$Utils$Html$viewIf,
			!$elm$core$String$isEmpty(classNames),
			A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(styleClass + (' ' + classNames)),
						A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true')
					]),
				_List_Nil));
	});
var $author$project$Shared$Icons$icon = $author$project$Shared$Icons$view('far');
var $author$project$PhoneNumber$generalClickTarget = 'Phone Number';
var $author$project$PhoneNumber$generalNumber = '0800 043 6600';
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $author$project$Utils$Html$emptyAttribute = $elm$html$Html$Attributes$classList(_List_Nil);
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Utils$Maybe$unwrap = F3(
	function (_default, fn, maybe) {
		return A2(
			$elm$core$Maybe$withDefault,
			_default,
			A2($elm$core$Maybe$map, fn, maybe));
	});
var $author$project$Utils$Html$attributeMaybe = function (attrFn) {
	return A2($author$project$Utils$Maybe$unwrap, $author$project$Utils$Html$emptyAttribute, attrFn);
};
var $author$project$Ga4$Main$clickTarget = $elm$core$Tuple$pair('click_target');
var $elm$core$String$endsWith = _String_endsWith;
var $author$project$Ga4$Main$isClick = $elm$core$String$endsWith('click');
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$Ga4$Main$dasherize = A2($elm$core$String$replace, '_', '-');
var $author$project$Ga4$Main$dataAttrPrefix = 'data-ga4-';
var $author$project$Ga4$Main$toDataAttribute = function (_v0) {
	var attr = _v0.a;
	var val = _v0.b;
	return A2(
		$elm$html$Html$Attributes$attribute,
		_Utils_ap(
			$author$project$Ga4$Main$dataAttrPrefix,
			$author$project$Ga4$Main$dasherize(attr)),
		val);
};
var $author$project$Ga4$Main$customClickAttr = F2(
	function (clickEvent, params) {
		return $author$project$Ga4$Main$isClick(clickEvent) ? A2(
			$elm$core$List$cons,
			$author$project$Ga4$Main$toDataAttribute(
				_Utils_Tuple2('click', clickEvent)),
			A2($elm$core$List$map, $author$project$Ga4$Main$toDataAttribute, params)) : _List_Nil;
	});
var $author$project$Ga4$Main$eventGroup = $elm$core$Tuple$pair('event_group');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$core$String$filter = _String_filter;
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $author$project$PhoneNumber$numberToHref = function (number) {
	return 'tel:+44' + A2(
		$elm$core$String$filter,
		function (c) {
			return $elm$core$String$fromChar(c) !== ' ';
		},
		A2($elm$core$String$dropLeft, 1, number));
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $author$project$PhoneNumber$link = F5(
	function (className, content, number, clickTarget, eventGroup) {
		return A2(
			$elm$html$Html$a,
			_Utils_ap(
				A2(
					$author$project$Ga4$Main$customClickAttr,
					'phone_click',
					A2(
						$elm$core$List$cons,
						$author$project$Ga4$Main$clickTarget(clickTarget),
						A3(
							$author$project$Utils$Maybe$unwrap,
							_List_Nil,
							A2($elm$core$Basics$composeR, $author$project$Ga4$Main$eventGroup, $elm$core$List$singleton),
							eventGroup))),
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href(
						$author$project$PhoneNumber$numberToHref(number)),
						A2($author$project$Utils$Html$attributeMaybe, $elm$html$Html$Attributes$class, className),
						A2($elm$html$Html$Attributes$attribute, 'data-exit-intent-ignore-links', '')
					])),
			_List_fromArray(
				[content]));
	});
var $author$project$PhoneNumber$phoneLink = function (number) {
	return A3(
		$author$project$PhoneNumber$link,
		$elm$core$Maybe$Just('link--underline'),
		$elm$html$Html$text(number),
		number);
};
var $author$project$PhoneNumber$generalLink = A2($author$project$PhoneNumber$phoneLink, $author$project$PhoneNumber$generalNumber, $author$project$PhoneNumber$generalClickTarget);
var $author$project$Shared$Views$phoneLink = _List_fromArray(
	[
		$elm$html$Html$text(' Need help? Call our customer service team on '),
		$author$project$PhoneNumber$generalLink($elm$core$Maybe$Nothing),
		$elm$html$Html$text('.')
	]);
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Utils$Html$maybeRender = function (renderFn) {
	return A2(
		$author$project$Utils$Maybe$unwrap,
		$elm$html$Html$text(''),
		renderFn);
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$core$String$trim = _String_trim;
var $author$project$Shared$Views$splitNoticeText = F2(
	function (notice, content) {
		var _v0 = function () {
			var _v1 = A2(
				$elm$core$List$filter,
				A2($elm$core$Basics$composeR, $elm$core$String$isEmpty, $elm$core$Basics$not),
				A2(
					$elm$core$String$split,
					'.',
					$elm$core$String$trim(notice)));
			if (_v1.b) {
				if (!_v1.b.b) {
					return $elm$core$List$isEmpty(content) ? _Utils_Tuple2(
						$elm$core$Maybe$Nothing,
						$elm$core$Maybe$Just(notice)) : _Utils_Tuple2(
						$elm$core$Maybe$Just(notice),
						$elm$core$Maybe$Nothing);
				} else {
					var first = _v1.a;
					var rest = _v1.b;
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(first + '.'),
						$elm$core$Maybe$Just(
							A2($elm$core$String$join, '.', rest) + '.'));
				}
			} else {
				return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
			}
		}();
		var heading = _v0.a;
		var others = _v0.b;
		return _Utils_ap(
			_List_fromArray(
				[
					A2(
					$author$project$Utils$Html$maybeRender,
					A2(
						$elm$core$Basics$composeR,
						$elm$html$Html$text,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$List$singleton,
							$elm$html$Html$span(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('utils:text-strong')
									])))),
					heading),
					A2($author$project$Utils$Html$maybeRender, $elm$html$Html$text, others)
				]),
			content);
	});
var $author$project$Shared$Views$noticeView = F3(
	function (themeClass, iconClass, notice) {
		return A2(
			$elm$html$Html$aside,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui-component-notice width:content size:sm ' + themeClass)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$header,
							_List_Nil,
							_List_fromArray(
								[
									$author$project$Shared$Icons$icon(iconClass)
								])),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							function () {
								switch (notice.$) {
									case 0:
										var content = notice.a;
										return content;
									case 1:
										var str = notice.a;
										return A2($author$project$Shared$Views$splitNoticeText, str, _List_Nil);
									case 2:
										var str = notice.a;
										return A2($author$project$Shared$Views$splitNoticeText, str, $author$project$Shared$Views$phoneLink);
									default:
										var str = notice.a;
										var content = notice.b;
										return A2(
											$author$project$Shared$Views$splitNoticeText,
											str,
											_Utils_ap(content, $author$project$Shared$Views$phoneLink));
								}
							}())
						]))
				]));
	});
var $author$project$Shared$Views$errorNotice = A2(
	$elm$core$Basics$composeR,
	$author$project$Shared$Views$NoticeWithText,
	A2($author$project$Shared$Views$noticeView, 'theme:alert', 'fa-exclamation-circle'));
var $author$project$Utils$Browser$view = F2(
	function (appConfig, modelWrapper) {
		if (!modelWrapper.$) {
			return $author$project$Shared$Views$errorNotice('There was an issue loading this page, our team is looking into it now.');
		} else {
			var model = modelWrapper.a;
			return appConfig.cH(model);
		}
	});
var $author$project$Utils$Browser$elementThatMightFail = function (appConfig) {
	return $elm$browser$Browser$element(
		{
			b5: $author$project$Utils$Browser$init(appConfig),
			cy: $author$project$Utils$Browser$subscriptions(appConfig),
			cF: $author$project$Utils$Browser$update(appConfig),
			cH: $author$project$Utils$Browser$view(appConfig)
		});
};
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Shared$Ports$flagDecodingError = _Platform_outgoingPort(
	'flagDecodingError',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Search$Main$CloseModal = {$: 8};
var $author$project$Search$Main$DatepickerMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Search$Main$FocusModal = function (a) {
	return {$: 7, a: a};
};
var $author$project$Search$Main$LengthOfStayChanged = function (a) {
	return {$: 10, a: a};
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$SearchDatepicker$encodeMultiDateFlexibility = function (flexibility) {
	if (flexibility === 1) {
		return $elm$json$Json$Encode$int(2);
	} else {
		return $elm$json$Json$Encode$int(3);
	}
};
var $author$project$SearchDatepicker$encodeSingleDateFlexibility = function (flexibility) {
	if (!flexibility) {
		return $elm$json$Json$Encode$null;
	} else {
		return $elm$json$Json$Encode$int(1);
	}
};
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$DateUtils$Transform$epochStartOffset = 719162;
var $author$project$DateUtils$Transform$millisPerDay = ((1000 * 60) * 60) * 24;
var $justinmimbs$date$Date$toRataDie = function (_v0) {
	var rd = _v0;
	return rd;
};
var $author$project$DateUtils$Transform$toMillis = function (date) {
	return (($justinmimbs$date$Date$toRataDie(date) - $author$project$DateUtils$Transform$epochStartOffset) * $author$project$DateUtils$Transform$millisPerDay) - $author$project$DateUtils$Transform$millisPerDay;
};
var $author$project$SearchDatepicker$encode = function (_v0) {
	var currentMonth = _v0.e;
	var selection = _v0.d;
	if (selection.$ === 1) {
		var date = selection.a;
		return A3(
			$author$project$Utils$Maybe$unwrap,
			$elm$json$Json$Encode$null,
			A2(
				$elm$core$Basics$composeR,
				$author$project$DateUtils$Transform$toMillis,
				A2(
					$elm$core$Basics$composeR,
					$elm$json$Json$Encode$int,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Tuple$pair('selected'),
						A2($elm$core$Basics$composeR, $elm$core$List$singleton, $elm$json$Json$Encode$object)))),
			date);
	} else {
		switch (selection.a.$) {
			case 0:
				var _v2 = selection.a;
				var date = _v2.a;
				var flexibility = _v2.b;
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'selected',
							$elm$json$Json$Encode$int(
								$author$project$DateUtils$Transform$toMillis(date))),
							_Utils_Tuple2(
							'flexibility',
							$author$project$SearchDatepicker$encodeSingleDateFlexibility(flexibility))
						]));
			case 1:
				var flexibility = selection.a.a;
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'selected',
							$elm$json$Json$Encode$int(
								$author$project$DateUtils$Transform$toMillis(currentMonth))),
							_Utils_Tuple2(
							'flexibility',
							$author$project$SearchDatepicker$encodeMultiDateFlexibility(flexibility))
						]));
			default:
				var _v3 = selection.a;
				return $elm$json$Json$Encode$null;
		}
	}
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $miyamoen$select_list$Types$toTuple = function (_v0) {
	var before = _v0.a;
	var a = _v0.b;
	var after = _v0.c;
	return _Utils_Tuple3(
		$elm$core$List$reverse(before),
		a,
		after);
};
var $miyamoen$select_list$SelectList$toTuple = $miyamoen$select_list$Types$toTuple;
var $author$project$Shared$Encoders$encodeSelectList = F2(
	function (encoder, list) {
		var _v0 = $miyamoen$select_list$SelectList$toTuple(list);
		var before = _v0.a;
		var selected = _v0.b;
		var after = _v0.c;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'before',
					A2($elm$json$Json$Encode$list, encoder, before)),
					_Utils_Tuple2(
					'selected',
					encoder(selected)),
					_Utils_Tuple2(
					'after',
					A2($elm$json$Json$Encode$list, encoder, after))
				]));
	});
var $author$project$Utils$List$fromTuple = function (_v0) {
	var first = _v0.a;
	var last = _v0.b;
	return _List_fromArray(
		[first, last]);
};
var $author$project$Shared$Encoders$encodeTuple = F2(
	function (encoder, tuple) {
		return A2(
			$elm$json$Json$Encode$list,
			encoder,
			$author$project$Utils$List$fromTuple(tuple));
	});
var $miyamoen$select_list$Transform$map = F2(
	function (f, _v0) {
		var before = _v0.a;
		var a = _v0.b;
		var after = _v0.c;
		return A3(
			$miyamoen$select_list$Types$SelectList,
			A2($elm$core$List$map, f, before),
			f(a),
			A2($elm$core$List$map, f, after));
	});
var $miyamoen$select_list$SelectList$map = $miyamoen$select_list$Transform$map;
var $author$project$Shared$Select$optionToTuple = function (_v0) {
	var value = _v0.j;
	var name = _v0.A;
	return _Utils_Tuple2(value, name);
};
var $author$project$Shared$Select$encodeAsSelectList = function (_v0) {
	var attributes = _v0;
	return A2(
		$author$project$Shared$Encoders$encodeSelectList,
		$author$project$Shared$Encoders$encodeTuple($elm$json$Json$Encode$string),
		A2($miyamoen$select_list$SelectList$map, $author$project$Shared$Select$optionToTuple, attributes.q));
};
var $author$project$Autocomplete$encode = function (_v0) {
	var autocomplete = _v0;
	return $elm$json$Json$Encode$object(
		A3(
			$author$project$Utils$Maybe$unwrap,
			_List_Nil,
			function (result) {
				return _List_fromArray(
					[
						_Utils_Tuple2(
						'name',
						$elm$json$Json$Encode$string(result.A)),
						_Utils_Tuple2(
						'slug',
						$elm$json$Json$Encode$string(result.am))
					]);
			},
			autocomplete.Z));
};
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $author$project$LocationFilter$encodeLocation = function (location) {
	return $elm$json$Json$Encode$object(
		function () {
			switch (location.$) {
				case 1:
					var result = location.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'name',
							$elm$json$Json$Encode$string(result.A)),
							_Utils_Tuple2(
							'slug',
							$elm$json$Json$Encode$string(result.am))
						]);
				case 2:
					var result = location.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'name',
							$elm$json$Json$Encode$string(result.A)),
							_Utils_Tuple2(
							'slug',
							$elm$json$Json$Encode$string(result.am)),
							_Utils_Tuple2(
							'distance',
							$elm$json$Json$Encode$int(result.aD))
						]);
				default:
					return _List_Nil;
			}
		}());
};
var $author$project$LocationFilter$encode = function (_v0) {
	var filter = _v0;
	var _v1 = $miyamoen$select_list$SelectList$toTuple(filter.af);
	var before = _v1.a;
	var selected = _v1.b;
	var after = _v1.c;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'before',
				A2(
					$elm$json$Json$Encode$list,
					$author$project$LocationFilter$encodeLocation,
					A2($elm$core$List$drop, 1, before))),
				_Utils_Tuple2(
				'selected',
				$author$project$LocationFilter$encodeLocation(selected)),
				_Utils_Tuple2(
				'after',
				A2($elm$json$Json$Encode$list, $author$project$LocationFilter$encodeLocation, after))
			]));
};
var $author$project$Search$Main$locationEncoder = function (location) {
	if (!location.$) {
		var locationFilter = location.a;
		return $author$project$LocationFilter$encode(locationFilter);
	} else {
		var autocomplete = location.a;
		return $author$project$Autocomplete$encode(autocomplete);
	}
};
var $author$project$Search$Main$encodedState = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'date',
				$author$project$SearchDatepicker$encode(model.r)),
				_Utils_Tuple2(
				'lengthsOfStay',
				$author$project$Shared$Select$encodeAsSelectList(model.x)),
				_Utils_Tuple2(
				'location',
				$author$project$Search$Main$locationEncoder(
					function () {
						var _v0 = model.g;
						if (!_v0.b.$) {
							var persisted = _v0.b.a;
							return persisted;
						} else {
							var input = _v0.a;
							return input;
						}
					}()))
			]));
};
var $author$project$Shared$Select$Select = $elm$core$Basics$identity;
var $author$project$Shared$Select$tupleToOption = function (_v0) {
	var value = _v0.a;
	var name = _v0.b;
	return {F: false, A: name, j: value};
};
var $author$project$Shared$Select$fromSelectList = F3(
	function (labelText, inputHandler, optionsList) {
		return {
			y: _List_fromArray(
				[
					_Utils_Tuple2('ui-component-select', true)
				]),
			o: '',
			H: '',
			N: $elm$core$Maybe$Nothing,
			aG: '',
			I: labelText,
			K: inputHandler,
			q: A2($miyamoen$select_list$SelectList$map, $author$project$Shared$Select$tupleToOption, optionsList),
			D: false
		};
	});
var $author$project$Search$Main$ViewportChanged = function (a) {
	return {$: 3, a: a};
};
var $elm$browser$Browser$Dom$getViewport = _Browser_withWindow(_Browser_getViewport);
var $author$project$Search$Main$getViewport = A2($elm$core$Task$perform, $author$project$Search$Main$ViewportChanged, $elm$browser$Browser$Dom$getViewport);
var $author$project$SearchDatepicker$CurrentDateFetched = function (a) {
	return {$: 10, a: a};
};
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $justinmimbs$date$Date$today = A3($elm$core$Task$map2, $justinmimbs$date$Date$fromPosix, $elm$time$Time$here, $elm$time$Time$now);
var $author$project$SearchDatepicker$initCmd = A2($elm$core$Task$perform, $author$project$SearchDatepicker$CurrentDateFetched, $justinmimbs$date$Date$today);
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Shared$Modal$Initial = 2;
var $author$project$Shared$Modal$Modal = $elm$core$Basics$identity;
var $author$project$Shared$Modal$new = F3(
	function (id, closeMsg, focusMsg) {
		return {az: $elm$core$Maybe$Nothing, aA: $elm$core$Maybe$Nothing, at: closeMsg, bb: focusMsg, aG: id, S: 2};
	});
var $author$project$SearchDatepicker$Auto = 2;
var $author$project$SearchDatepicker$Closed = 1;
var $author$project$SearchDatepicker$new = F2(
	function (currentMonth, selection) {
		return {e: currentMonth, M: $elm$core$Maybe$Nothing, R: 2, d: selection, S: 1, T: $author$project$SearchDatepicker$initDate};
	});
var $author$project$SearchDatepicker$newWithFlexibility = function (_v0) {
	var currentMonth = _v0.a;
	var selection = _v0.b;
	return A2($author$project$SearchDatepicker$new, currentMonth, selection);
};
var $author$project$SearchDatepicker$Inflexible = function (a) {
	return {$: 1, a: a};
};
var $author$project$SearchDatepicker$newWithoutFlexibility = function (_v0) {
	var currentMonth = _v0.a;
	var selection = _v0.b;
	return A2(
		$author$project$SearchDatepicker$new,
		currentMonth,
		function () {
			if (!selection.$) {
				if (!selection.a.$) {
					var _v2 = selection.a;
					var date = _v2.a;
					return $author$project$SearchDatepicker$Inflexible(
						$elm$core$Maybe$Just(date));
				} else {
					return $author$project$SearchDatepicker$Inflexible($elm$core$Maybe$Nothing);
				}
			} else {
				return selection;
			}
		}());
};
var $author$project$Search$Main$ready = _Platform_outgoingPort('ready', $elm$core$Basics$identity);
var $author$project$Search$Main$init = function (flags) {
	var model = {
		ab: flags.ab,
		r: A3(
			$author$project$Utils$Maybe$unwrap,
			$author$project$SearchDatepicker$newWithFlexibility(flags.r),
			$elm$core$Basics$always(
				$author$project$SearchDatepicker$newWithoutFlexibility(flags.r)),
			flags.aZ),
		au: flags,
		x: A3($author$project$Shared$Select$fromSelectList, 'Length of stay', $author$project$Search$Main$LengthOfStayChanged, flags.x),
		g: A3(
			$author$project$Utils$Maybe$unwrap,
			_Utils_Tuple2(flags.g, $elm$core$Maybe$Nothing),
			function (venue) {
				return _Utils_Tuple2(
					venue,
					$elm$core$Maybe$Just(flags.g));
			},
			flags.aZ),
		O: A3($author$project$Shared$Modal$new, 'elm-search-modal', $author$project$Search$Main$CloseModal, $author$project$Search$Main$FocusModal),
		ax: flags.aI,
		ar: $elm$core$Maybe$Nothing
	};
	return _Utils_Tuple2(
		model,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Search$Main$ready(
					$author$project$Search$Main$encodedState(model)),
					$author$project$Search$Main$getViewport,
					A2($elm$core$Platform$Cmd$map, $author$project$Search$Main$DatepickerMsg, $author$project$SearchDatepicker$initCmd)
				])));
};
var $author$project$Search$Main$GetViewport = {$: 2};
var $author$project$Search$Main$SetFilterCount = function (a) {
	return {$: 4, a: a};
};
var $author$project$Search$Main$SetPath = function (a) {
	return {$: 5, a: a};
};
var $author$project$Autocomplete$FuzzySearchResults = function (a) {
	return {$: 0, a: a};
};
var $author$project$Autocomplete$FuzzySearchLocations = F2(
	function (regions, venues) {
		return {aT: regions, a_: venues};
	});
var $author$project$Autocomplete$Location = function (a) {
	return {$: 1, a: a};
};
var $author$project$Autocomplete$Region = 0;
var $author$project$Autocomplete$AutocompleteResult = F3(
	function (name, slug, resultType) {
		return {A: name, ay: resultType, am: slug};
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded = A2($elm$core$Basics$composeR, $elm$json$Json$Decode$succeed, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom);
var $author$project$Autocomplete$resultDecoder = function (resultType) {
	return A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
		resultType,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'slug',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'name',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Autocomplete$AutocompleteResult))));
};
var $author$project$Autocomplete$regionDecoder = $author$project$Autocomplete$resultDecoder(
	$author$project$Autocomplete$Location(0));
var $author$project$Autocomplete$Venue = {$: 0};
var $author$project$Autocomplete$venueDecoder = $author$project$Autocomplete$resultDecoder($author$project$Autocomplete$Venue);
var $author$project$Autocomplete$locationsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'venues',
	$elm$json$Json$Decode$list($author$project$Autocomplete$venueDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'regions',
		$elm$json$Json$Decode$list($author$project$Autocomplete$regionDecoder),
		$elm$json$Json$Decode$succeed($author$project$Autocomplete$FuzzySearchLocations)));
var $author$project$Autocomplete$decodeFuse = F2(
	function (msg, val) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Autocomplete$locationsDecoder, val);
		if (!_v0.$) {
			var locations = _v0.a;
			return msg.bn(
				$author$project$Autocomplete$FuzzySearchResults(locations));
		} else {
			return msg.bm;
		}
	});
var $author$project$Search$Main$decodeFuse = $author$project$Autocomplete$decodeFuse(
	{
		bm: $author$project$Search$Main$AutocompleteMessage($author$project$Search$Main$NoOp),
		bn: A2($elm$core$Basics$composeR, $author$project$Search$Main$ReceivedResults, $author$project$Search$Main$AutocompleteMessage)
	});
var $author$project$Search$Main$filterCountChanged = _Platform_incomingPort('filterCountChanged', $elm$json$Json$Decode$int);
var $elm$core$Platform$Sub$map = _Platform_map;
var $author$project$Search$Main$pathChanged = _Platform_incomingPort('pathChanged', $elm$json$Json$Decode$string);
var $author$project$Autocomplete$receiveFuse = _Platform_incomingPort('receiveFuse', $elm$json$Json$Decode$value);
var $author$project$SearchDatepicker$SetPosition = function (a) {
	return {$: 11, a: a};
};
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$SearchDatepicker$setRelativePosition = _Platform_incomingPort(
	'setRelativePosition',
	A2(
		$elm$json$Json$Decode$andThen,
		function (outerWidth) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (boundingClientRect) {
					return $elm$json$Json$Decode$succeed(
						{aB: boundingClientRect, aP: outerWidth});
				},
				A2(
					$elm$json$Json$Decode$field,
					'boundingClientRect',
					$elm$json$Json$Decode$oneOf(
						_List_fromArray(
							[
								$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
								A2(
								$elm$json$Json$Decode$map,
								$elm$core$Maybe$Just,
								A2(
									$elm$json$Json$Decode$andThen,
									function (y) {
										return A2(
											$elm$json$Json$Decode$andThen,
											function (x) {
												return A2(
													$elm$json$Json$Decode$andThen,
													function (width) {
														return A2(
															$elm$json$Json$Decode$andThen,
															function (top) {
																return A2(
																	$elm$json$Json$Decode$andThen,
																	function (right) {
																		return A2(
																			$elm$json$Json$Decode$andThen,
																			function (left) {
																				return A2(
																					$elm$json$Json$Decode$andThen,
																					function (height) {
																						return A2(
																							$elm$json$Json$Decode$andThen,
																							function (bottom) {
																								return $elm$json$Json$Decode$succeed(
																									{bQ: bottom, b0: height, b8: left, cq: right, cE: top, cJ: width, a0: x, cL: y});
																							},
																							A2($elm$json$Json$Decode$field, 'bottom', $elm$json$Json$Decode$float));
																					},
																					A2($elm$json$Json$Decode$field, 'height', $elm$json$Json$Decode$float));
																			},
																			A2($elm$json$Json$Decode$field, 'left', $elm$json$Json$Decode$float));
																	},
																	A2($elm$json$Json$Decode$field, 'right', $elm$json$Json$Decode$float));
															},
															A2($elm$json$Json$Decode$field, 'top', $elm$json$Json$Decode$float));
													},
													A2($elm$json$Json$Decode$field, 'width', $elm$json$Json$Decode$float));
											},
											A2($elm$json$Json$Decode$field, 'x', $elm$json$Json$Decode$float));
									},
									A2($elm$json$Json$Decode$field, 'y', $elm$json$Json$Decode$float)))
							]))));
		},
		A2($elm$json$Json$Decode$field, 'outerWidth', $elm$json$Json$Decode$float)));
var $author$project$SearchDatepicker$subscriptions = $author$project$SearchDatepicker$setRelativePosition($author$project$SearchDatepicker$SetPosition);
var $author$project$Shared$Modal$keypressDecoder = F2(
	function (msg, key) {
		return (key === 'Escape') ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('');
	});
var $author$project$Shared$Modal$closeIfEscape = function (closeMsg) {
	return A2(
		$elm$json$Json$Decode$andThen,
		$author$project$Shared$Modal$keypressDecoder(closeMsg),
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string));
};
var $elm$browser$Browser$Events$Document = 0;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {bo: pids, bF: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {a8: event, bj: key};
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.bo,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.bj;
		var event = _v0.a8;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.bF);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onKeyDown = A2($elm$browser$Browser$Events$on, 0, 'keydown');
var $author$project$Shared$Modal$subscriptions = function (_v0) {
	var modal = _v0;
	var _v1 = modal.S;
	if (!_v1) {
		return $elm$browser$Browser$Events$onKeyDown(
			$author$project$Shared$Modal$closeIfEscape(modal.at));
	} else {
		return $elm$core$Platform$Sub$none;
	}
};
var $author$project$Search$Main$viewportChanged = _Platform_incomingPort(
	'viewportChanged',
	$elm$json$Json$Decode$null(0));
var $author$project$Search$Main$subscriptions = function (_v0) {
	var modal = _v0.O;
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Autocomplete$receiveFuse($author$project$Search$Main$decodeFuse),
				$author$project$Search$Main$filterCountChanged($author$project$Search$Main$SetFilterCount),
				$author$project$Search$Main$pathChanged($author$project$Search$Main$SetPath),
				$author$project$Search$Main$viewportChanged(
				$elm$core$Basics$always($author$project$Search$Main$GetViewport)),
				$author$project$Shared$Modal$subscriptions(modal),
				A2($elm$core$Platform$Sub$map, $author$project$Search$Main$DatepickerMsg, $author$project$SearchDatepicker$subscriptions)
			]));
};
var $author$project$Shared$Modal$Closed = 1;
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$Shared$Modal$modalOpened = _Platform_outgoingPort('modalOpened', $elm$json$Json$Encode$bool);
var $author$project$Shared$Modal$close = function (_v0) {
	var modal = _v0;
	return _Utils_Tuple2(
		_Utils_update(
			modal,
			{S: 1}),
		$author$project$Shared$Modal$modalOpened(false));
};
var $author$project$Search$Url$emptySearchUrlParts = {as: $elm$core$Maybe$Nothing, b9: $elm$core$Maybe$Nothing, g: $elm$core$Maybe$Nothing, ak: $elm$core$Dict$empty, E: $elm$core$Dict$empty};
var $author$project$Search$Url$collectionsKey = 'collections';
var $author$project$Search$Url$dateKey = 'date';
var $author$project$Search$Url$priceRangeKey = 'price_range';
var $author$project$Search$Url$knownOnePartSegments = _List_fromArray(
	[$author$project$Search$Url$priceRangeKey, $author$project$Search$Url$dateKey, $author$project$Search$Url$collectionsKey]);
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Search$Url$setLengthOfStay = F2(
	function (los, urlParts) {
		return _Utils_update(
			urlParts,
			{b9: los});
	});
var $author$project$Search$Url$Place = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Search$Url$setPlace = F3(
	function (slug, maxDistance, urlParts) {
		return _Utils_update(
			urlParts,
			{
				g: $elm$core$Maybe$Just(
					A2($author$project$Search$Url$Place, slug, maxDistance))
			});
	});
var $author$project$Search$Url$Region = function (a) {
	return {$: 1, a: a};
};
var $author$project$Search$Url$setRegion = F2(
	function (slug, urlParts) {
		return _Utils_update(
			urlParts,
			{
				g: $elm$core$Maybe$Just(
					$author$project$Search$Url$Region(slug))
			});
	});
var $author$project$Search$Url$setSegmentPart = F3(
	function (key, value, urlParts) {
		return _Utils_update(
			urlParts,
			{
				E: A3($elm$core$Dict$insert, key, value, urlParts.E)
			});
	});
var $author$project$Search$Url$buildUrlParts = F2(
	function (segments, urlParts) {
		buildUrlParts:
		while (true) {
			_v0$8:
			while (true) {
				_v0$9:
				while (true) {
					if (!segments.b) {
						return urlParts;
					} else {
						switch (segments.a) {
							case 'regions':
								if (segments.b.b) {
									var _v1 = segments.b;
									var regionSlug = _v1.a;
									var rest = _v1.b;
									return A2(
										$author$project$Search$Url$buildUrlParts,
										rest,
										A2($author$project$Search$Url$setRegion, regionSlug, urlParts));
								} else {
									break _v0$9;
								}
							case 'place':
								if (segments.b.b) {
									if ((segments.b.b.b && (segments.b.b.a === 'max_distance')) && segments.b.b.b.b) {
										var _v2 = segments.b;
										var placeSlug = _v2.a;
										var _v3 = _v2.b;
										var _v4 = _v3.b;
										var distance = _v4.a;
										var rest = _v4.b;
										return A2(
											$author$project$Search$Url$buildUrlParts,
											rest,
											A3($author$project$Search$Url$setPlace, placeSlug, distance, urlParts));
									} else {
										break _v0$8;
									}
								} else {
									break _v0$9;
								}
							case 'categories':
								if (segments.b.b) {
									var _v5 = segments.b;
									var categorySegment = _v5.a;
									var rest = _v5.b;
									return A2(
										$author$project$Search$Url$buildUrlParts,
										rest,
										_Utils_update(
											urlParts,
											{
												as: $elm$core$Maybe$Just(categorySegment)
											}));
								} else {
									break _v0$9;
								}
							case 'spa_days':
								var rest = segments.b;
								return A2(
									$author$project$Search$Url$buildUrlParts,
									rest,
									A2(
										$author$project$Search$Url$setLengthOfStay,
										$elm$core$Maybe$Just('spa_days'),
										urlParts));
							case 'spa_breaks':
								var rest = segments.b;
								return A2(
									$author$project$Search$Url$buildUrlParts,
									rest,
									A2(
										$author$project$Search$Url$setLengthOfStay,
										$elm$core$Maybe$Just('spa_breaks'),
										urlParts));
							case 'length_of_stay':
								if (segments.b.b) {
									var _v6 = segments.b;
									var losSegment = _v6.a;
									var rest = _v6.b;
									return A2(
										$author$project$Search$Url$buildUrlParts,
										rest,
										A2(
											$author$project$Search$Url$setLengthOfStay,
											$elm$core$Maybe$Just('length_of_stay' + ('/' + losSegment)),
											urlParts));
								} else {
									break _v0$9;
								}
							case 'sort':
								if (segments.b.b) {
									if (segments.b.b.b) {
										var _v7 = segments.b;
										var sortType = _v7.a;
										var _v8 = _v7.b;
										var order = _v8.a;
										var rest = _v8.b;
										return A2(
											$author$project$Search$Url$buildUrlParts,
											rest,
											A3($author$project$Search$Url$setSegmentPart, 'sort', sortType + ('/' + order), urlParts));
									} else {
										break _v0$8;
									}
								} else {
									break _v0$9;
								}
							default:
								if (segments.b.b) {
									break _v0$8;
								} else {
									break _v0$9;
								}
						}
					}
				}
				return urlParts;
			}
			var key = segments.a;
			var _v9 = segments.b;
			var val = _v9.a;
			var rest = _v9.b;
			if (A2($elm$core$List$member, key, $author$project$Search$Url$knownOnePartSegments)) {
				return A2(
					$author$project$Search$Url$buildUrlParts,
					rest,
					A3($author$project$Search$Url$setSegmentPart, key, val, urlParts));
			} else {
				var $temp$segments = rest,
					$temp$urlParts = urlParts;
				segments = $temp$segments;
				urlParts = $temp$urlParts;
				continue buildUrlParts;
			}
		}
	});
var $author$project$Search$Url$parsePath = F2(
	function (fullPath, urlParts) {
		var _v0 = $elm$core$String$uncons(fullPath);
		if ((!_v0.$) && ('/' === _v0.a.a)) {
			var _v1 = _v0.a;
			var path = _v1.b;
			return A2(
				$author$project$Search$Url$buildUrlParts,
				A2($elm$core$String$split, '/', path),
				urlParts);
		} else {
			return urlParts;
		}
	});
var $author$project$Search$Url$knownQueryParts = _List_fromArray(
	['category_ids', 'facility_ids', 'rating', 'treatment_ids']);
var $author$project$Search$Url$setQueryPart = F3(
	function (key, value, urlParts) {
		return _Utils_update(
			urlParts,
			{
				ak: A3($elm$core$Dict$insert, key, value, urlParts.ak)
			});
	});
var $author$project$Search$Url$parseQuerySegments = F2(
	function (queryParts, urlParts) {
		if (!queryParts.b) {
			return urlParts;
		} else {
			var queryPart = queryParts.a;
			var rest = queryParts.b;
			var _v1 = A2($elm$core$String$split, '=', queryPart);
			if ((_v1.b && _v1.b.b) && (!_v1.b.b.b)) {
				var key = _v1.a;
				var _v2 = _v1.b;
				var val = _v2.a;
				return A2($elm$core$List$member, key, $author$project$Search$Url$knownQueryParts) ? A2(
					$author$project$Search$Url$parseQuerySegments,
					rest,
					A3($author$project$Search$Url$setQueryPart, key, val, urlParts)) : urlParts;
			} else {
				return urlParts;
			}
		}
	});
var $author$project$Search$Url$parseQuery = F2(
	function (query, urlParts) {
		return A2(
			$author$project$Search$Url$parseQuerySegments,
			A2($elm$core$String$split, '&', query),
			urlParts);
	});
var $author$project$Search$Url$fromString = function (path) {
	var _v0 = A2($elm$core$String$split, '?', path);
	_v0$2:
	while (true) {
		if (_v0.b) {
			if (!_v0.b.b) {
				var segments = _v0.a;
				return A2($author$project$Search$Url$parsePath, segments, $author$project$Search$Url$emptySearchUrlParts);
			} else {
				if (!_v0.b.b.b) {
					var segments = _v0.a;
					var _v1 = _v0.b;
					var query = _v1.a;
					return A2(
						$author$project$Search$Url$parseQuery,
						query,
						A2($author$project$Search$Url$parsePath, segments, $author$project$Search$Url$emptySearchUrlParts));
				} else {
					break _v0$2;
				}
			}
		} else {
			break _v0$2;
		}
	}
	return $author$project$Search$Url$emptySearchUrlParts;
};
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $author$project$Search$Url$setDate = F2(
	function (date, urlParts) {
		if (!date.$) {
			var dateSegment = date.a;
			return A3($author$project$Search$Url$setSegmentPart, $author$project$Search$Url$dateKey, dateSegment, urlParts);
		} else {
			return _Utils_update(
				urlParts,
				{
					E: A2($elm$core$Dict$remove, $author$project$Search$Url$dateKey, urlParts.E)
				});
		}
	});
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $author$project$Search$Url$removeDistanceSort = function (segmentParts) {
	var _v0 = A2($elm$core$Dict$get, 'sort', segmentParts);
	if ((!_v0.$) && (_v0.a === 'distance/asc')) {
		return A2($elm$core$Dict$remove, 'sort', segmentParts);
	} else {
		return segmentParts;
	}
};
var $author$project$Search$Url$setLocation = F2(
	function (location, urlParts) {
		if ((!location.$) && (location.a.$ === 1)) {
			return _Utils_update(
				urlParts,
				{
					g: location,
					E: $author$project$Search$Url$removeDistanceSort(urlParts.E)
				});
		} else {
			return _Utils_update(
				urlParts,
				{g: location});
		}
	});
var $author$project$Search$Url$generateCategorySegment = function (urlParts) {
	var _v0 = urlParts.as;
	if (!_v0.$) {
		var slug = _v0.a;
		return _List_fromArray(
			['categories', slug]);
	} else {
		return _List_Nil;
	}
};
var $author$project$Search$Url$generateLosSegment = function (urlParts) {
	var _v0 = urlParts.b9;
	if (!_v0.$) {
		var los = _v0.a;
		return _List_fromArray(
			[los]);
	} else {
		return _List_Nil;
	}
};
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $author$project$Search$Url$generateSegmentsFromDict = function (urlParts) {
	return A2(
		$elm$core$List$concatMap,
		$author$project$Utils$List$fromTuple,
		$elm$core$Dict$toList(urlParts.E));
};
var $author$project$Search$Url$generateSegmentParts = function (urlParts) {
	return _Utils_ap(
		$author$project$Search$Url$generateCategorySegment(urlParts),
		_Utils_ap(
			$author$project$Search$Url$generateLosSegment(urlParts),
			$author$project$Search$Url$generateSegmentsFromDict(urlParts)));
};
var $author$project$Search$Url$generatePath = F2(
	function (locationSegments, urlParts) {
		var _v0 = A2(
			$elm$core$String$join,
			'/',
			_Utils_ap(
				locationSegments,
				$author$project$Search$Url$generateSegmentParts(urlParts)));
		if (_v0 === '') {
			return 'search';
		} else {
			var s = _v0;
			return s;
		}
	});
var $author$project$Search$Url$toQuery = function (_v0) {
	var key = _v0.a;
	var val = _v0.b;
	return A2(
		$elm$core$String$join,
		'=',
		_List_fromArray(
			[key, val]));
};
var $author$project$Search$Url$generateQueryParts = function (urlParts) {
	return A2(
		$elm$core$String$join,
		'&',
		A2(
			$elm$core$List$map,
			$author$project$Search$Url$toQuery,
			$elm$core$Dict$toList(urlParts.ak)));
};
var $author$project$Search$Url$generateUrl = F2(
	function (locationSegments, urlParts) {
		return A2(
			$elm$core$String$cons,
			'/',
			A2(
				$elm$core$String$join,
				'?',
				A2(
					$elm$core$List$filter,
					A2($elm$core$Basics$composeR, $elm$core$String$isEmpty, $elm$core$Basics$not),
					_List_fromArray(
						[
							A2($author$project$Search$Url$generatePath, locationSegments, urlParts),
							$author$project$Search$Url$generateQueryParts(urlParts)
						]))));
	});
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $author$project$Utils$Dict$getWithKey = F2(
	function (key, dict) {
		return A2(
			$elm$core$Maybe$map,
			$elm$core$Tuple$pair(key),
			A2($elm$core$Dict$get, key, dict));
	});
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Search$Url$venueExperienceParam = function (lengthOfStay) {
	return _Utils_Tuple2(
		'experience',
		A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(
				$elm$core$List$reverse(
					A2($elm$core$String$split, '/', lengthOfStay)))));
};
var $author$project$Search$Url$venuePageParams = function (urlParts) {
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Maybe$map(
			function (_v0) {
				var key = _v0.a;
				var val = _v0.b;
				return A2($elm$url$Url$Builder$string, key, val);
			}),
		_List_fromArray(
			[
				A2($elm$core$Maybe$map, $author$project$Search$Url$venueExperienceParam, urlParts.b9),
				A2($author$project$Utils$Dict$getWithKey, $author$project$Search$Url$dateKey, urlParts.E)
			]));
};
var $author$project$Search$Url$generateVenueUrl = F2(
	function (venueSlug, urlParts) {
		return A2(
			$elm$url$Url$Builder$absolute,
			_List_fromArray(
				['venues', venueSlug]),
			$author$project$Search$Url$venuePageParams(urlParts));
	});
var $author$project$Search$Url$toUrl = function (urlParts) {
	var _v0 = urlParts.g;
	if (!_v0.$) {
		switch (_v0.a.$) {
			case 0:
				var slug = _v0.a.a;
				return A2($author$project$Search$Url$generateVenueUrl, slug, urlParts);
			case 1:
				var slug = _v0.a.a;
				return A2(
					$author$project$Search$Url$generateUrl,
					_List_fromArray(
						['regions', slug]),
					urlParts);
			default:
				var _v1 = _v0.a;
				var slug = _v1.a;
				var distance = _v1.b;
				return A2(
					$author$project$Search$Url$generateUrl,
					_List_fromArray(
						['place', slug, 'max_distance', distance]),
					urlParts);
		}
	} else {
		return A2($author$project$Search$Url$generateUrl, _List_Nil, urlParts);
	}
};
var $author$project$Search$Url$generate = function (urlConfig) {
	return $author$project$Search$Url$toUrl(
		A2(
			$author$project$Search$Url$setDate,
			urlConfig.r,
			A2(
				$author$project$Search$Url$setLengthOfStay,
				urlConfig.b9,
				A2(
					$author$project$Search$Url$setLocation,
					urlConfig.g,
					$author$project$Search$Url$fromString(urlConfig.aI)))));
};
var $author$project$Search$Main$isSearchPage = function (_v0) {
	var flags = _v0.au;
	return flags.aQ === 1;
};
var $author$project$Autocomplete$isVenue = function (_v0) {
	var autocomplete = _v0;
	var _v1 = autocomplete.Z;
	if (!_v1.$) {
		var resultType = _v1.a.ay;
		if (!resultType.$) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};
var $author$project$LocationFilter$isVenue = function (_v0) {
	var filter = _v0;
	var _v1 = $miyamoen$select_list$SelectList$selected(filter.af);
	if (_v1.$ === 3) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Ga4$Main$saveGa4ToStorage = _Platform_outgoingPort(
	'saveGa4ToStorage',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$json$Json$Encode$string(b)
				]));
	});
var $author$project$Ga4$Main$navigationType = function (value) {
	return $author$project$Ga4$Main$saveGa4ToStorage(
		_Utils_Tuple2('navigation_type', value));
};
var $author$project$Shared$Modal$Open = 0;
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$browser$Browser$Dom$focus = _Browser_call('focus');
var $author$project$Shared$Modal$focusModal = F2(
	function (id, focusMsg) {
		return A2(
			$elm$core$Task$attempt,
			focusMsg,
			$elm$browser$Browser$Dom$focus(id));
	});
var $author$project$Shared$Modal$open = function (_v0) {
	var modal = _v0;
	return _Utils_Tuple2(
		_Utils_update(
			modal,
			{S: 0}),
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2($author$project$Shared$Modal$focusModal, modal.aG, modal.bb),
					$author$project$Shared$Modal$modalOpened(true)
				])));
};
var $author$project$SearchDatepicker$removeFlexibility = function (model) {
	var _v0 = model.d;
	if (!_v0.$) {
		if (!_v0.a.$) {
			var _v1 = _v0.a;
			var date = _v1.a;
			return _Utils_update(
				model,
				{
					d: $author$project$SearchDatepicker$Inflexible(
						$elm$core$Maybe$Just(date))
				});
		} else {
			return _Utils_update(
				model,
				{
					d: $author$project$SearchDatepicker$Inflexible($elm$core$Maybe$Nothing)
				});
		}
	} else {
		return model;
	}
};
var $author$project$Search$Main$search = _Platform_outgoingPort('search', $elm$json$Json$Encode$string);
var $author$project$Shared$Select$toSelectedValue = function (_v0) {
	var attributes = _v0;
	return $miyamoen$select_list$SelectList$selected(attributes.q).j;
};
var $justinmimbs$date$Date$Days = 3;
var $justinmimbs$date$Date$Months = 1;
var $author$project$SearchDatepicker$Open = 0;
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {a5: d, bk: m, bL: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		aN: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		bL: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.bL, 0, date.aN);
};
var $justinmimbs$date$Date$add = F3(
	function (unit, n, _v0) {
		var rd = _v0;
		switch (unit) {
			case 0:
				return A3($justinmimbs$date$Date$add, 1, 12 * n, rd);
			case 1:
				var date = $justinmimbs$date$Date$toCalendarDate(rd);
				var wholeMonths = ((12 * (date.bL - 1)) + ($justinmimbs$date$Date$monthToNumber(date.bk) - 1)) + n;
				var m = $justinmimbs$date$Date$numberToMonth(
					A2($elm$core$Basics$modBy, 12, wholeMonths) + 1);
				var y = A2($justinmimbs$date$Date$floorDiv, wholeMonths, 12) + 1;
				return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A2(
					$elm$core$Basics$min,
					date.a5,
					A2($justinmimbs$date$Date$daysInMonth, y, m));
			case 2:
				return rd + (7 * n);
			default:
				return rd + n;
		}
	});
var $author$project$Ga4$Main$pushGa4Event = _Platform_outgoingPort('pushGa4Event', $elm$core$Basics$identity);
var $author$project$Ga4$Main$pushEncodedEvent = F2(
	function (eventName, encodedParams) {
		return $author$project$Ga4$Main$pushGa4Event(
			$elm$json$Json$Encode$object(
				A2(
					$elm$core$List$cons,
					_Utils_Tuple2(
						'event',
						$elm$json$Json$Encode$string(eventName)),
					encodedParams)));
	});
var $author$project$Ga4$Main$toEncodedParam = function (param) {
	switch (param.$) {
		case 0:
			var val = param.a;
			return _Utils_Tuple2(
				'index',
				$elm$json$Json$Encode$string(val));
		case 1:
			var val = param.a;
			return _Utils_Tuple2(
				'item_id',
				$elm$json$Json$Encode$string(val));
		case 2:
			var val = param.a;
			return _Utils_Tuple2(
				'item_list_name',
				$elm$json$Json$Encode$string(val));
		default:
			var val = param.a;
			return _Utils_Tuple2(
				'item_name',
				$elm$json$Json$Encode$string(val));
	}
};
var $author$project$Ga4$Main$viewOrSelectOrClearContent = F4(
	function (eventName, optionalParams, contentType, itemName) {
		return A2(
			$author$project$Ga4$Main$pushEncodedEvent,
			eventName,
			_Utils_ap(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'content_type',
						$elm$json$Json$Encode$string(contentType)),
						_Utils_Tuple2(
						'item_name',
						$elm$json$Json$Encode$string(itemName))
					]),
				A2($elm$core$List$map, $author$project$Ga4$Main$toEncodedParam, optionalParams)));
	});
var $author$project$Ga4$Main$clearContentWith = $author$project$Ga4$Main$viewOrSelectOrClearContent('clear_content');
var $author$project$Ga4$Main$clearContent = $author$project$Ga4$Main$clearContentWith(_List_Nil);
var $author$project$SearchDatepicker$datepickerOpen = _Platform_outgoingPort('datepickerOpen', $elm$json$Json$Encode$bool);
var $author$project$SearchDatepicker$fetchRelativePosition = _Platform_outgoingPort('fetchRelativePosition', $elm$json$Json$Encode$string);
var $author$project$SearchDatepicker$flexibilityToString = function (flexibility) {
	if (!flexibility.$) {
		if (!flexibility.a) {
			var _v1 = flexibility.a;
			return 'Specific date';
		} else {
			var _v2 = flexibility.a;
			return 'Flexible date';
		}
	} else {
		if (!flexibility.a) {
			var _v3 = flexibility.a;
			return 'Any weekend';
		} else {
			var _v4 = flexibility.a;
			return 'Any weekday';
		}
	}
};
var $author$project$SearchDatepicker$ga4ContentType = 'Search Datepicker';
var $author$project$SearchDatepicker$hasSelectionChanged = F2(
	function (oldModel, newModel) {
		if (_Utils_eq(oldModel.d, newModel.d)) {
			var _v0 = oldModel.d;
			if ((!_v0.$) && (_v0.a.$ === 1)) {
				return !_Utils_eq(oldModel.e, newModel.e);
			} else {
				return false;
			}
		} else {
			return true;
		}
	});
var $author$project$SearchDatepicker$inputBlur = function (model) {
	return _Utils_update(
		model,
		{
			e: function () {
				var _v0 = model.d;
				if (_v0.$ === 1) {
					var date = _v0.a;
					return A2($elm$core$Maybe$withDefault, model.e, date);
				} else {
					if (!_v0.a.$) {
						var _v1 = _v0.a;
						var date = _v1.a;
						return date;
					} else {
						return model.e;
					}
				}
			}(),
			S: 1
		});
};
var $author$project$SearchDatepicker$pickerClass = 'elm-datepicker';
var $author$project$SearchDatepicker$removeHighlighted = function (model) {
	return _Utils_update(
		model,
		{M: $elm$core$Maybe$Nothing});
};
var $author$project$Ga4$Main$selectContentWith = $author$project$Ga4$Main$viewOrSelectOrClearContent('select_content');
var $author$project$Ga4$Main$selectContent = $author$project$Ga4$Main$selectContentWith(_List_Nil);
var $author$project$SearchDatepicker$Left = 0;
var $author$project$SearchDatepicker$Right = 1;
var $author$project$SearchDatepicker$setPosition = F2(
	function (_v0, model) {
		var boundingClientRect = _v0.aB;
		var outerWidth = _v0.aP;
		if (boundingClientRect.$ === 1) {
			return model;
		} else {
			var rect = boundingClientRect.a;
			return (rect.a0 < 0) ? _Utils_update(
				model,
				{R: 0}) : ((_Utils_cmp(rect.a0 + rect.cJ, outerWidth) > 0) ? _Utils_update(
				model,
				{R: 1}) : model);
		}
	});
var $author$project$SearchDatepicker$selectionFromFlexibility = F2(
	function (flexibility, _v0) {
		var selection = _v0.d;
		var _v1 = _Utils_Tuple2(flexibility, selection);
		if (!_v1.a.$) {
			if ((!_v1.b.$) && (!_v1.b.a.$)) {
				var singleFlexibility = _v1.a.a;
				var _v2 = _v1.b.a;
				var date = _v2.a;
				return $author$project$SearchDatepicker$Flexible(
					A2($author$project$SearchDatepicker$SingleDate, date, singleFlexibility));
			} else {
				return selection;
			}
		} else {
			var multiFlexibility = _v1.a.a;
			return $author$project$SearchDatepicker$Flexible(
				$author$project$SearchDatepicker$MultiDate(multiFlexibility));
		}
	});
var $author$project$SearchDatepicker$updateFlexibility = F2(
	function (flexibility, model) {
		return _Utils_update(
			model,
			{
				d: A2($author$project$SearchDatepicker$selectionFromFlexibility, flexibility, model)
			});
	});
var $author$project$SearchDatepicker$updateHighlightedDate = F2(
	function (date, model) {
		return _Utils_update(
			model,
			{
				M: $elm$core$Maybe$Just(date)
			});
	});
var $author$project$SearchDatepicker$updateSelectedDate = F2(
	function (date, model) {
		return _Utils_update(
			model,
			{
				d: function () {
					var _v0 = model.d;
					if (_v0.$ === 1) {
						return $author$project$SearchDatepicker$Inflexible(
							$elm$core$Maybe$Just(date));
					} else {
						if ((!_v0.a.$) && (_v0.a.b === 1)) {
							var _v1 = _v0.a;
							var _v2 = _v1.b;
							return $author$project$SearchDatepicker$Flexible(
								A2($author$project$SearchDatepicker$SingleDate, date, 1));
						} else {
							return $author$project$SearchDatepicker$Flexible(
								A2($author$project$SearchDatepicker$SingleDate, date, 0));
						}
					}
				}()
			});
	});
var $author$project$SearchDatepicker$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							e: A3($justinmimbs$date$Date$add, 1, -1, model.e)
						}),
					A2($author$project$Ga4$Main$selectContent, $author$project$SearchDatepicker$ga4ContentType, 'Previous month'));
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							e: A3($justinmimbs$date$Date$add, 1, 1, model.e)
						}),
					A2($author$project$Ga4$Main$selectContent, $author$project$SearchDatepicker$ga4ContentType, 'Next month'));
			case 4:
				var date = msg.a;
				var updatedModel = A2($author$project$SearchDatepicker$updateSelectedDate, date, model);
				return _Utils_Tuple2(
					updatedModel,
					A2($author$project$SearchDatepicker$hasSelectionChanged, model, updatedModel) ? A2($author$project$Ga4$Main$selectContent, $author$project$SearchDatepicker$ga4ContentType, 'Select date') : $elm$core$Platform$Cmd$none);
			case 5:
				var date = msg.a;
				return _Utils_Tuple2(
					A2($author$project$SearchDatepicker$updateHighlightedDate, date, model),
					$elm$core$Platform$Cmd$none);
			case 6:
				return _Utils_Tuple2(
					$author$project$SearchDatepicker$removeHighlighted(model),
					$elm$core$Platform$Cmd$none);
			case 9:
				var flexibility = msg.a;
				var updatedModel = A2($author$project$SearchDatepicker$updateFlexibility, flexibility, model);
				var flexibilityStr = $author$project$SearchDatepicker$flexibilityToString(flexibility);
				return _Utils_Tuple2(
					updatedModel,
					A2($author$project$SearchDatepicker$hasSelectionChanged, model, updatedModel) ? A2($author$project$Ga4$Main$selectContent, $author$project$SearchDatepicker$ga4ContentType, flexibilityStr) : $elm$core$Platform$Cmd$none);
			case 7:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							e: model.T,
							d: function () {
								var _v1 = model.d;
								if (_v1.$ === 1) {
									return $author$project$SearchDatepicker$Inflexible($elm$core$Maybe$Nothing);
								} else {
									return $author$project$SearchDatepicker$Flexible($author$project$SearchDatepicker$NoDate);
								}
							}(),
							S: 1
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$SearchDatepicker$datepickerOpen(false),
								A2($author$project$Ga4$Main$clearContent, $author$project$SearchDatepicker$ga4ContentType, 'Clear date')
							])));
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{S: 1}),
					$author$project$SearchDatepicker$datepickerOpen(false));
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{S: 0}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$SearchDatepicker$datepickerOpen(true),
								$author$project$SearchDatepicker$fetchRelativePosition($author$project$SearchDatepicker$pickerClass)
							])));
			case 1:
				return _Utils_Tuple2(
					$author$project$SearchDatepicker$inputBlur(model),
					$author$project$SearchDatepicker$datepickerOpen(false));
			case 10:
				var date = msg.a;
				var tomorrow = A3($justinmimbs$date$Date$add, 3, 1, date);
				var current = function () {
					var _v2 = model.d;
					if (_v2.$ === 1) {
						var selection = _v2.a;
						return A3(
							$author$project$Utils$Maybe$unwrap,
							tomorrow,
							$elm$core$Basics$always(model.e),
							selection);
					} else {
						if (_v2.a.$ === 2) {
							var _v3 = _v2.a;
							return tomorrow;
						} else {
							return model.e;
						}
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{e: current, T: tomorrow}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var relativePosition = msg.a;
				return _Utils_Tuple2(
					A2($author$project$SearchDatepicker$setPosition, relativePosition, model),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $miyamoen$select_list$SelectList$attempt = F2(
	function (action, selectList) {
		return A2(
			$elm$core$Maybe$withDefault,
			selectList,
			action(selectList));
	});
var $miyamoen$select_list$Types$reverseAppend = F2(
	function (xs, ys) {
		return A3($elm$core$List$foldl, $elm$core$List$cons, ys, xs);
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $miyamoen$select_list$Select$splitAt = F2(
	function (n, list) {
		var _v0 = _Utils_Tuple2(
			A2($elm$core$List$take, n - 1, list),
			A2($elm$core$List$drop, n - 1, list));
		var before = _v0.a;
		var rest = _v0.b;
		if (rest.b) {
			var a = rest.a;
			var after = rest.b;
			return $elm$core$Maybe$Just(
				_Utils_Tuple3(before, a, after));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $miyamoen$select_list$Select$by = F2(
	function (n, _v0) {
		var before = _v0.a;
		var a = _v0.b;
		var after = _v0.c;
		return (n > 0) ? A2(
			$elm$core$Maybe$map,
			function (_v1) {
				var nextBefore = _v1.a;
				var next = _v1.b;
				var nextAfter = _v1.c;
				return A3(
					$miyamoen$select_list$Types$SelectList,
					A2(
						$miyamoen$select_list$Types$reverseAppend,
						nextBefore,
						A2($elm$core$List$cons, a, before)),
					next,
					nextAfter);
			},
			A2($miyamoen$select_list$Select$splitAt, n, after)) : ((n < 0) ? A2(
			$elm$core$Maybe$map,
			function (_v2) {
				var nextAfter = _v2.a;
				var next = _v2.b;
				var nextBefore = _v2.c;
				return A3(
					$miyamoen$select_list$Types$SelectList,
					nextBefore,
					next,
					A2(
						$miyamoen$select_list$Types$reverseAppend,
						nextAfter,
						A2($elm$core$List$cons, a, after)));
			},
			A2($miyamoen$select_list$Select$splitAt, -n, before)) : $elm$core$Maybe$Just(
			A3($miyamoen$select_list$Types$SelectList, before, a, after)));
	});
var $miyamoen$select_list$SelectList$selectBy = $miyamoen$select_list$Select$by;
var $author$project$Shared$Select$update = F2(
	function (offset, _v0) {
		var attributes = _v0;
		var _v1 = $elm$core$String$toInt(offset);
		if (!_v1.$) {
			var val = _v1.a;
			return _Utils_update(
				attributes,
				{
					o: '',
					q: A2(
						$miyamoen$select_list$SelectList$attempt,
						$miyamoen$select_list$SelectList$selectBy(val),
						attributes.q)
				});
		} else {
			return attributes;
		}
	});
var $author$project$Search$Main$AutocompleteLookup = function (a) {
	return {$: 1, a: a};
};
var $author$project$Autocomplete$autocompleteBlurred = _Platform_outgoingPort(
	'autocompleteBlurred',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Autocomplete$autocompleteFocused = _Platform_outgoingPort('autocompleteFocused', $elm$core$Basics$identity);
var $author$project$Autocomplete$boolToString = function (bool) {
	return bool ? 'true' : 'false';
};
var $author$project$Autocomplete$buildResult = F3(
	function (_v0, index, result) {
		var onHover = _v0.ad;
		var onSelect = _v0.ae;
		return {
			aH: index,
			ad: onHover(index),
			ae: onSelect,
			al: result
		};
	});
var $author$project$Autocomplete$uniquePlaceResults = function (_v0) {
	var locations = _v0.av;
	var places = _v0.aR;
	var uniqueFn = F2(
		function (elem, acc) {
			return A2(
				$elm$core$List$any,
				function (loc) {
					return _Utils_eq(loc.A, elem.A);
				},
				acc) ? acc : A2($elm$core$List$cons, elem, acc);
		});
	var regions = locations.aT;
	var dupNames = A2(
		$elm$core$List$map,
		function (loc) {
			return loc.A + (', ' + loc.A);
		},
		regions);
	var filteredPlaces = A2(
		$elm$core$List$filter,
		function (loc) {
			return !A2($elm$core$List$member, loc.A, dupNames);
		},
		places);
	return A3(
		$elm$core$List$foldr,
		uniqueFn,
		_List_Nil,
		$elm$core$List$concat(
			_List_fromArray(
				[regions, filteredPlaces])));
};
var $author$project$Autocomplete$buildResults = function (_v0) {
	var autocomplete = _v0;
	return A2(
		$elm$core$List$indexedMap,
		$author$project$Autocomplete$buildResult(autocomplete),
		_Utils_ap(
			$author$project$Autocomplete$uniquePlaceResults(autocomplete),
			autocomplete.av.a_));
};
var $author$project$Autocomplete$internalUpdateResults = function (_v0) {
	var autocomplete = _v0;
	var results = $author$project$Autocomplete$buildResults(autocomplete);
	var expanded = $author$project$Autocomplete$boolToString(
		!$elm$core$List$isEmpty(autocomplete.af));
	return _Utils_update(
		autocomplete,
		{
			G: $elm$core$Maybe$Nothing,
			z: A2(
				$author$project$Shared$Input$withHtmlAttrs,
				_List_fromArray(
					[
						_Utils_Tuple2('aria-expanded', expanded)
					]),
				autocomplete.z),
			af: results
		});
};
var $author$project$Autocomplete$updateLocations = F2(
	function (locations, _v0) {
		var autocomplete = _v0;
		return $author$project$Autocomplete$internalUpdateResults(
			_Utils_update(
				autocomplete,
				{av: locations}));
	});
var $author$project$Autocomplete$updatePlaces = F2(
	function (places, _v0) {
		var autocomplete = _v0;
		return $author$project$Autocomplete$internalUpdateResults(
			_Utils_update(
				autocomplete,
				{aR: places}));
	});
var $author$project$Autocomplete$blur = A2(
	$elm$core$Basics$composeR,
	$author$project$Autocomplete$updateLocations($author$project$Autocomplete$emptyLocations),
	$author$project$Autocomplete$updatePlaces(_List_Nil));
var $author$project$Shared$Input$update = F2(
	function (value, _v0) {
		var attributes = _v0;
		return _Utils_update(
			attributes,
			{o: '', j: value});
	});
var $author$project$Autocomplete$updateText = F2(
	function (str, _v0) {
		var autocomplete = _v0;
		return _Utils_update(
			autocomplete,
			{
				z: A2($author$project$Shared$Input$update, str, autocomplete.z)
			});
	});
var $author$project$Autocomplete$clearAndSetText = A2(
	$elm$core$Basics$composeR,
	$author$project$Autocomplete$updateText(''),
	A2(
		$elm$core$Basics$composeR,
		$author$project$Autocomplete$updateLocations($author$project$Autocomplete$emptyLocations),
		$author$project$Autocomplete$updatePlaces(_List_Nil)));
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Autocomplete$change = F3(
	function (text, autocomplete, searchMsg) {
		if (text === '') {
			return _Utils_Tuple2(
				$author$project$Autocomplete$clearAndSetText(autocomplete),
				$elm$core$Platform$Cmd$none);
		} else {
			var str = text;
			return _Utils_Tuple2(
				A2($author$project$Autocomplete$updateText, str, autocomplete),
				A2(
					$elm$core$Task$perform,
					$elm$core$Basics$always(
						searchMsg(str)),
					$elm$core$Process$sleep(250)));
		}
	});
var $author$project$Autocomplete$hover = F2(
	function (index, _v0) {
		var autocomplete = _v0;
		return _Utils_update(
			autocomplete,
			{
				G: $elm$core$Maybe$Just(index)
			});
	});
var $author$project$Shared$Input$toValue = function (_v0) {
	var input = _v0;
	return input.j;
};
var $author$project$Autocomplete$inputText = function (_v0) {
	var autocomplete = _v0;
	return $author$project$Shared$Input$toValue(autocomplete.z);
};
var $author$project$Autocomplete$scrollDown = function (_v0) {
	var highlightedIndex = _v0.G;
	var results = _v0.af;
	if (highlightedIndex.$ === 1) {
		return $elm$core$Maybe$Just(0);
	} else {
		var _int = highlightedIndex.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$core$Basics$min,
				$elm$core$List$length(results) - 1,
				_int + 1));
	}
};
var $author$project$Autocomplete$scrollUp = function (maybeInt) {
	return A2(
		$elm$core$Maybe$map,
		function (_int) {
			return A2($elm$core$Basics$max, 0, _int - 1);
		},
		maybeInt);
};
var $author$project$Autocomplete$SelectedResult = $elm$core$Basics$identity;
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{m: nodeList, i: nodeListSize, l: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $author$project$Autocomplete$resultByIndex = function (_v0) {
	var highlightedIndex = _v0.G;
	var results = _v0.af;
	return A2(
		$elm$core$Maybe$andThen,
		function (index) {
			return A2(
				$elm$core$Array$get,
				index,
				$elm$core$Array$fromList(results));
		},
		highlightedIndex);
};
var $author$project$Autocomplete$updateSelected = F2(
	function (result, _v0) {
		var autocomplete = _v0;
		return $author$project$Autocomplete$internalUpdateResults(
			_Utils_update(
				autocomplete,
				{
					Z: $elm$core$Maybe$Just(result)
				}));
	});
var $author$project$Autocomplete$select = F2(
	function (_v0, autocomplete) {
		var result = _v0;
		return A2(
			$author$project$Autocomplete$updatePlaces,
			_List_Nil,
			A2(
				$author$project$Autocomplete$updateLocations,
				$author$project$Autocomplete$emptyLocations,
				A2(
					$author$project$Autocomplete$updateSelected,
					result,
					A2($author$project$Autocomplete$updateText, result.A, autocomplete))));
	});
var $author$project$Autocomplete$selectHighlighted = function (autocomplete) {
	var _v0 = $author$project$Autocomplete$resultByIndex(autocomplete);
	if (!_v0.$) {
		var result = _v0.a.al;
		return A2($author$project$Autocomplete$select, result, autocomplete);
	} else {
		return autocomplete;
	}
};
var $author$project$Autocomplete$keyPress = F2(
	function (key, _v0) {
		var autocomplete = _v0;
		switch (key) {
			case 0:
				return _Utils_update(
					autocomplete,
					{
						G: $author$project$Autocomplete$scrollUp(autocomplete.G)
					});
			case 1:
				return _Utils_update(
					autocomplete,
					{
						G: $author$project$Autocomplete$scrollDown(autocomplete)
					});
			case 2:
				return $author$project$Autocomplete$selectHighlighted(autocomplete);
			default:
				return $author$project$Autocomplete$blur(autocomplete);
		}
	});
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.cu));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {bw: reqs, bF: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.bH;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.bw));
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.bF)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					bM: r.bM,
					bP: r.bP,
					bX: A2(_Http_mapExpect, func, r.bX),
					bd: r.bd,
					cc: r.cc,
					cC: r.cC,
					bH: r.bH,
					cG: r.cG
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{bM: false, bP: r.bP, bX: r.bX, bd: r.bd, cc: r.cc, cC: r.cC, bH: r.bH, cG: r.cG}));
};
var $elm$http$Http$get = function (r) {
	return $elm$http$Http$request(
		{bP: $elm$http$Http$emptyBody, bX: r.bX, bd: _List_Nil, cc: 'GET', cC: $elm$core$Maybe$Nothing, bH: $elm$core$Maybe$Nothing, cG: r.cG});
};
var $author$project$Autocomplete$Place = 1;
var $author$project$Autocomplete$placeDecoder = $author$project$Autocomplete$resultDecoder(
	$author$project$Autocomplete$Location(1));
var $author$project$Autocomplete$PlaceSearchResults = function (a) {
	return {$: 1, a: a};
};
var $author$project$Autocomplete$resultsExpectation = F2(
	function (handlers, results) {
		if (!results.$) {
			var placeResults = results.a;
			return handlers.bn(
				$author$project$Autocomplete$PlaceSearchResults(placeResults));
		} else {
			return handlers.bm;
		}
	});
var $author$project$Autocomplete$searchPlaces = F2(
	function (str, _v0) {
		var autocomplete = _v0;
		return $elm$http$Http$get(
			{
				bX: A2(
					$elm$http$Http$expectJson,
					$author$project$Autocomplete$resultsExpectation(autocomplete.aS),
					$elm$json$Json$Decode$list($author$project$Autocomplete$placeDecoder)),
				cG: A2(
					$elm$url$Url$Builder$absolute,
					_List_fromArray(
						['place_search']),
					_List_fromArray(
						[
							A2($elm$url$Url$Builder$string, 'q', str)
						]))
			});
	});
var $author$project$Autocomplete$sendFuse = _Platform_outgoingPort('sendFuse', $elm$json$Json$Encode$string);
var $author$project$Autocomplete$lookup = F2(
	function (text, autocomplete) {
		if (text === '') {
			return $elm$core$Platform$Cmd$none;
		} else {
			var str = text;
			return $elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$Autocomplete$sendFuse(str),
						A2($author$project$Autocomplete$searchPlaces, str, autocomplete)
					]));
		}
	});
var $author$project$Autocomplete$updateResults = F2(
	function (results, autocomplete) {
		if (!results.$) {
			var locations = results.a;
			return A2($author$project$Autocomplete$updateLocations, locations, autocomplete);
		} else {
			var places = results.a;
			return A2($author$project$Autocomplete$updatePlaces, places, autocomplete);
		}
	});
var $author$project$Search$Main$updateAutocomplete = F2(
	function (msg, model) {
		var _v0 = model.g;
		if (_v0.a.$ === 1) {
			var autocomplete = _v0.a.a;
			var persisted = _v0.b;
			switch (msg.$) {
				case 5:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								g: _Utils_Tuple2(
									$author$project$Search$Main$Suggest(
										$author$project$Autocomplete$blur(autocomplete)),
									persisted)
							}),
						$author$project$Autocomplete$autocompleteBlurred(0));
				case 0:
					var text = msg.a;
					var _v2 = A3(
						$author$project$Autocomplete$change,
						text,
						autocomplete,
						A2($elm$core$Basics$composeR, $author$project$Search$Main$AutocompleteLookup, $author$project$Search$Main$AutocompleteMessage));
					var newAutocomplete = _v2.a;
					var cmds = _v2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								g: _Utils_Tuple2(
									$author$project$Search$Main$Suggest(newAutocomplete),
									persisted)
							}),
						cmds);
				case 1:
					var text = msg.a;
					return _Utils_Tuple2(
						model,
						_Utils_eq(
							text,
							$author$project$Autocomplete$inputText(autocomplete)) ? A2($author$project$Autocomplete$lookup, text, autocomplete) : $elm$core$Platform$Cmd$none);
				case 6:
					var event = msg.a;
					return _Utils_Tuple2(
						model,
						$author$project$Autocomplete$autocompleteFocused(event));
				case 3:
					var index = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								g: _Utils_Tuple2(
									$author$project$Search$Main$Suggest(
										A2($author$project$Autocomplete$hover, index, autocomplete)),
									persisted)
							}),
						$elm$core$Platform$Cmd$none);
				case 2:
					var direction = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								g: _Utils_Tuple2(
									$author$project$Search$Main$Suggest(
										A2($author$project$Autocomplete$keyPress, direction, autocomplete)),
									persisted)
							}),
						$elm$core$Platform$Cmd$none);
				case 4:
					var result = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								g: _Utils_Tuple2(
									$author$project$Search$Main$Suggest(
										A2($author$project$Autocomplete$select, result, autocomplete)),
									persisted)
							}),
						$elm$core$Platform$Cmd$none);
				case 7:
					var results = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								g: _Utils_Tuple2(
									$author$project$Search$Main$Suggest(
										A2($author$project$Autocomplete$updateResults, results, autocomplete)),
									persisted)
							}),
						$elm$core$Platform$Cmd$none);
				default:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Search$Main$LocationLookup = function (a) {
	return {$: 3, a: a};
};
var $author$project$LocationFilter$blur = function (_v0) {
	var filter = _v0;
	return _Utils_update(
		filter,
		{S: 1});
};
var $author$project$LocationFilter$hover = F2(
	function (index, _v0) {
		var filter = _v0;
		return _Utils_update(
			filter,
			{G: index});
	});
var $author$project$LocationFilter$Open = 0;
var $author$project$LocationFilter$focus = function (_v0) {
	var filter = _v0;
	return _Utils_update(
		filter,
		{S: 0});
};
var $miyamoen$select_list$Query$length = function (_v0) {
	var before = _v0.a;
	var after = _v0.c;
	return ($elm$core$List$length(before) + 1) + $elm$core$List$length(after);
};
var $miyamoen$select_list$SelectList$length = $miyamoen$select_list$Query$length;
var $author$project$LocationFilter$resultCount = function (results) {
	return $miyamoen$select_list$SelectList$length(results) - 1;
};
var $author$project$LocationFilter$scrollDown = function (_v0) {
	var highlightedIndex = _v0.G;
	var results = _v0.af;
	return A2(
		$elm$core$Basics$min,
		$author$project$LocationFilter$resultCount(results),
		highlightedIndex + 1);
};
var $author$project$LocationFilter$scrollUp = function (_int) {
	return A2($elm$core$Basics$max, 0, _int - 1);
};
var $miyamoen$select_list$Query$beforeLength = function (_v0) {
	var before = _v0.a;
	return $elm$core$List$length(before);
};
var $miyamoen$select_list$SelectList$beforeLength = $miyamoen$select_list$Query$beforeLength;
var $miyamoen$select_list$SelectList$index = $miyamoen$select_list$SelectList$beforeLength;
var $author$project$LocationFilter$updateSelected = function (_v0) {
	var filter = _v0;
	var offset = filter.G - $miyamoen$select_list$SelectList$index(filter.af);
	var newResults = A2(
		$elm$core$Maybe$withDefault,
		filter.af,
		A2($miyamoen$select_list$SelectList$selectBy, offset, filter.af));
	return _Utils_update(
		filter,
		{G: 0, af: newResults});
};
var $author$project$LocationFilter$updateText = function (_v0) {
	var filter = _v0;
	return _Utils_update(
		filter,
		{
			z: A2(
				$author$project$Shared$Input$update,
				$author$project$LocationFilter$resultName(
					$miyamoen$select_list$SelectList$selected(filter.af)),
				filter.z)
		});
};
var $author$project$LocationFilter$select = function (filter) {
	return $author$project$LocationFilter$blur(
		$author$project$LocationFilter$updateText(
			$author$project$LocationFilter$updateSelected(filter)));
};
var $author$project$LocationFilter$keyPress = F2(
	function (key, _v0) {
		var filter = _v0;
		switch (key) {
			case 0:
				var _v2 = filter.S;
				if (!_v2) {
					return _Utils_update(
						filter,
						{
							G: $author$project$LocationFilter$scrollUp(filter.G)
						});
				} else {
					return $author$project$LocationFilter$focus(filter);
				}
			case 1:
				var _v3 = filter.S;
				if (!_v3) {
					return _Utils_update(
						filter,
						{
							G: $author$project$LocationFilter$scrollDown(filter)
						});
				} else {
					return $author$project$LocationFilter$focus(filter);
				}
			case 2:
				return $author$project$LocationFilter$select(filter);
			default:
				return $author$project$LocationFilter$blur(filter);
		}
	});
var $author$project$LocationFilter$mouseDown = function (_v0) {
	var filter = _v0;
	var state = function () {
		var _v1 = filter.S;
		if (!_v1) {
			return 1;
		} else {
			return 0;
		}
	}();
	return _Utils_update(
		filter,
		{S: state});
};
var $author$project$Search$Main$updateLocation = F2(
	function (msg, model) {
		var _v0 = model.g;
		if (!_v0.a.$) {
			var filter = _v0.a.a;
			var persisted = _v0.b;
			switch (msg.$) {
				case 0:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								g: _Utils_Tuple2(
									$author$project$Search$Main$Dropdown(
										$author$project$LocationFilter$blur(filter)),
									persisted)
							}),
						$author$project$Autocomplete$autocompleteBlurred(0));
				case 1:
					var event = msg.a;
					return _Utils_Tuple2(
						model,
						$author$project$Autocomplete$autocompleteFocused(event));
				case 2:
					var text = msg.a;
					var _v2 = A3(
						$author$project$Autocomplete$change,
						text,
						$author$project$Search$Main$initAutocomplete,
						A2($elm$core$Basics$composeR, $author$project$Search$Main$LocationLookup, $author$project$Search$Main$LocationMessage));
					var autocomplete = _v2.a;
					var cmds = _v2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								g: _Utils_Tuple2(
									$author$project$Search$Main$Suggest(autocomplete),
									persisted)
							}),
						cmds);
				case 3:
					var text = msg.a;
					return _Utils_Tuple2(
						model,
						_Utils_eq(
							text,
							$author$project$Autocomplete$inputText($author$project$Search$Main$initAutocomplete)) ? A2($author$project$Autocomplete$lookup, text, $author$project$Search$Main$initAutocomplete) : $elm$core$Platform$Cmd$none);
				case 4:
					var index = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								g: _Utils_Tuple2(
									$author$project$Search$Main$Dropdown(
										A2($author$project$LocationFilter$hover, index, filter)),
									persisted)
							}),
						$elm$core$Platform$Cmd$none);
				case 5:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								g: _Utils_Tuple2(
									$author$project$Search$Main$Dropdown(
										$author$project$LocationFilter$mouseDown(filter)),
									persisted)
							}),
						$elm$core$Platform$Cmd$none);
				case 6:
					var direction = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								g: _Utils_Tuple2(
									$author$project$Search$Main$Dropdown(
										A2($author$project$LocationFilter$keyPress, direction, filter)),
									persisted)
							}),
						$elm$core$Platform$Cmd$none);
				default:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								g: _Utils_Tuple2(
									$author$project$Search$Main$Dropdown(
										$author$project$LocationFilter$select(filter)),
									persisted)
							}),
						$elm$core$Platform$Cmd$none);
			}
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Search$Url$Venue = function (a) {
	return {$: 0, a: a};
};
var $author$project$Search$Url$defaultDistance = '50';
var $author$project$Autocomplete$generateUrlSegment = function (result) {
	var _v0 = result.ay;
	if (!_v0.$) {
		return $author$project$Search$Url$Venue(result.am);
	} else {
		var locationType = _v0.a;
		if (!locationType) {
			return $author$project$Search$Url$Region(result.am);
		} else {
			return A2($author$project$Search$Url$Place, result.am, $author$project$Search$Url$defaultDistance);
		}
	}
};
var $author$project$Autocomplete$urlSegment = function (_v0) {
	var autocomplete = _v0;
	return A2($elm$core$Maybe$map, $author$project$Autocomplete$generateUrlSegment, autocomplete.Z);
};
var $author$project$LocationFilter$urlSegment = function (_v0) {
	var filter = _v0;
	var _v1 = $miyamoen$select_list$SelectList$selected(filter.af);
	switch (_v1.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var slug = _v1.a.am;
			return $elm$core$Maybe$Just(
				$author$project$Search$Url$Region(slug));
		case 2:
			var distance = _v1.a.aD;
			var slug = _v1.a.am;
			return $elm$core$Maybe$Just(
				A2(
					$author$project$Search$Url$Place,
					slug,
					$elm$core$String$fromInt(distance)));
		default:
			var slug = _v1.a.am;
			return $elm$core$Maybe$Just(
				$author$project$Search$Url$Venue(slug));
	}
};
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.a5;
	});
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.bk;
	});
var $justinmimbs$date$Date$monthNumber = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToNumber);
var $justinmimbs$date$Date$ordinalDay = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toOrdinalDate,
	function ($) {
		return $.aN;
	});
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $justinmimbs$date$Date$padSignedInt = F2(
	function (length, _int) {
		return _Utils_ap(
			(_int < 0) ? '-' : '',
			A3(
				$elm$core$String$padLeft,
				length,
				'0',
				$elm$core$String$fromInt(
					$elm$core$Basics$abs(_int))));
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$daysBeforeWeekYear = function (y) {
	var jan4 = $justinmimbs$date$Date$daysBeforeYear(y) + 4;
	return jan4 - $justinmimbs$date$Date$weekdayNumber(jan4);
};
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $justinmimbs$date$Date$numberToWeekday = function (wdn) {
	var _v0 = A2($elm$core$Basics$max, 1, wdn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		default:
			return 6;
	}
};
var $justinmimbs$date$Date$toWeekDate = function (_v0) {
	var rd = _v0;
	var wdn = $justinmimbs$date$Date$weekdayNumber(rd);
	var wy = $justinmimbs$date$Date$year(rd + (4 - wdn));
	var week1Day1 = $justinmimbs$date$Date$daysBeforeWeekYear(wy) + 1;
	return {
		bJ: 1 + (((rd - week1Day1) / 7) | 0),
		bK: wy,
		cI: $justinmimbs$date$Date$numberToWeekday(wdn)
	};
};
var $justinmimbs$date$Date$weekNumber = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.bJ;
	});
var $justinmimbs$date$Date$weekYear = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.bK;
	});
var $justinmimbs$date$Date$weekday = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$weekdayNumber, $justinmimbs$date$Date$numberToWeekday);
var $justinmimbs$date$Date$ordinalSuffix = function (n) {
	var nn = A2($elm$core$Basics$modBy, 100, n);
	var _v0 = A2(
		$elm$core$Basics$min,
		(nn < 20) ? nn : A2($elm$core$Basics$modBy, 10, nn),
		4);
	switch (_v0) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
var $justinmimbs$date$Date$withOrdinalSuffix = function (n) {
	return _Utils_ap(
		$elm$core$String$fromInt(n),
		$justinmimbs$date$Date$ordinalSuffix(n));
};
var $justinmimbs$date$Date$formatField = F4(
	function (language, _char, length, date) {
		switch (_char) {
			case 'y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$year(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$year(date));
				}
			case 'Y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekYear(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$weekYear(date));
				}
			case 'Q':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 3:
						return 'Q' + $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 4:
						return $justinmimbs$date$Date$withOrdinalSuffix(
							$justinmimbs$date$Date$quarter(date));
					case 5:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					default:
						return '';
				}
			case 'M':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$monthNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$monthNumber(date)));
					case 3:
						return language.aw(
							$justinmimbs$date$Date$month(date));
					case 4:
						return language.aL(
							$justinmimbs$date$Date$month(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.aw(
								$justinmimbs$date$Date$month(date)));
					default:
						return '';
				}
			case 'w':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekNumber(date)));
					default:
						return '';
				}
			case 'd':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$day(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$day(date)));
					case 3:
						return language.aC(
							$justinmimbs$date$Date$day(date));
					default:
						return '';
				}
			case 'D':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$ordinalDay(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					case 3:
						return A3(
							$elm$core$String$padLeft,
							3,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					default:
						return '';
				}
			case 'E':
				switch (length) {
					case 1:
						return language.aa(
							$justinmimbs$date$Date$weekday(date));
					case 2:
						return language.aa(
							$justinmimbs$date$Date$weekday(date));
					case 3:
						return language.aa(
							$justinmimbs$date$Date$weekday(date));
					case 4:
						return language.a$(
							$justinmimbs$date$Date$weekday(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.aa(
								$justinmimbs$date$Date$weekday(date)));
					case 6:
						return A2(
							$elm$core$String$left,
							2,
							language.aa(
								$justinmimbs$date$Date$weekday(date)));
					default:
						return '';
				}
			case 'e':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					default:
						return A4($justinmimbs$date$Date$formatField, language, 'E', length, date);
				}
			default:
				return '';
		}
	});
var $justinmimbs$date$Date$formatWithTokens = F3(
	function (language, tokens, date) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (token, formatted) {
					if (!token.$) {
						var _char = token.a;
						var length = token.b;
						return _Utils_ap(
							A4($justinmimbs$date$Date$formatField, language, _char, length, date),
							formatted);
					} else {
						var str = token.a;
						return _Utils_ap(str, formatted);
					}
				}),
			'',
			tokens);
	});
var $justinmimbs$date$Pattern$Literal = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {a4: col, bU: contextStack, bq: problem, bA: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.bA, s.a4, x, s.c));
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.b, s.bA, s.a4, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{a4: newCol, c: s.c, f: s.f, b: newOffset, bA: newRow, a: s.a});
	};
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $justinmimbs$date$Pattern$escapedQuote = A2(
	$elm$parser$Parser$ignorer,
	$elm$parser$Parser$succeed(
		$justinmimbs$date$Pattern$Literal('\'')),
	$elm$parser$Parser$token('\'\''));
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.b, s.a);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{a4: 1, c: s.c, f: s.f, b: s.b + 1, bA: s.bA + 1, a: s.a}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{a4: s.a4 + 1, c: s.c, f: s.f, b: newOffset, bA: s.bA, a: s.a}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $justinmimbs$date$Pattern$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.a);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.b, offset) < 0,
					0,
					{a4: col, c: s0.c, f: s0.f, b: offset, bA: row, a: s0.a});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.b, s.bA, s.a4, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.b, s);
};
var $elm$parser$Parser$getOffset = $elm$parser$Parser$Advanced$getOffset;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $justinmimbs$date$Pattern$fieldRepeats = function (str) {
	var _v0 = $elm$core$String$toList(str);
	if (_v0.b && (!_v0.b.b)) {
		var _char = _v0.a;
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F2(
						function (x, y) {
							return A2($justinmimbs$date$Pattern$Field, _char, 1 + (y - x));
						})),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$getOffset,
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(_char)))),
			$elm$parser$Parser$getOffset);
	} else {
		return $elm$parser$Parser$problem('expected exactly one char');
	}
};
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.b, s1.b, s0.a),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $justinmimbs$date$Pattern$field = A2(
	$elm$parser$Parser$andThen,
	$justinmimbs$date$Pattern$fieldRepeats,
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompIf($elm$core$Char$isAlpha)));
var $justinmimbs$date$Pattern$finalize = A2(
	$elm$core$List$foldl,
	F2(
		function (token, tokens) {
			var _v0 = _Utils_Tuple2(token, tokens);
			if (((_v0.a.$ === 1) && _v0.b.b) && (_v0.b.a.$ === 1)) {
				var x = _v0.a.a;
				var _v1 = _v0.b;
				var y = _v1.a.a;
				var rest = _v1.b;
				return A2(
					$elm$core$List$cons,
					$justinmimbs$date$Pattern$Literal(
						_Utils_ap(x, y)),
					rest);
			} else {
				return A2($elm$core$List$cons, token, tokens);
			}
		}),
	_List_Nil);
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $elm$parser$Parser$lazy = $elm$parser$Parser$Advanced$lazy;
var $justinmimbs$date$Pattern$isLiteralChar = function (_char) {
	return (_char !== '\'') && (!$elm$core$Char$isAlpha(_char));
};
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $justinmimbs$date$Pattern$literal = A2(
	$elm$parser$Parser$map,
	$justinmimbs$date$Pattern$Literal,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($justinmimbs$date$Pattern$isLiteralChar)),
			$elm$parser$Parser$chompWhile($justinmimbs$date$Pattern$isLiteralChar))));
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.a),
			s.b) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $justinmimbs$date$Pattern$quotedHelp = function (result) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (str) {
					return $justinmimbs$date$Pattern$quotedHelp(
						_Utils_ap(result, str));
				},
				$elm$parser$Parser$getChompedString(
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed(0),
							$elm$parser$Parser$chompIf(
								$elm$core$Basics$neq('\''))),
						$elm$parser$Parser$chompWhile(
							$elm$core$Basics$neq('\''))))),
				A2(
				$elm$parser$Parser$andThen,
				function (_v0) {
					return $justinmimbs$date$Pattern$quotedHelp(result + '\'');
				},
				$elm$parser$Parser$token('\'\'')),
				$elm$parser$Parser$succeed(result)
			]));
};
var $justinmimbs$date$Pattern$quoted = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($justinmimbs$date$Pattern$Literal),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('\''))),
	A2(
		$elm$parser$Parser$ignorer,
		$justinmimbs$date$Pattern$quotedHelp(''),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('\'')),
					$elm$parser$Parser$end
				]))));
var $justinmimbs$date$Pattern$patternHelp = function (tokens) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (token) {
					return $justinmimbs$date$Pattern$patternHelp(
						A2($elm$core$List$cons, token, tokens));
				},
				$elm$parser$Parser$oneOf(
					_List_fromArray(
						[$justinmimbs$date$Pattern$field, $justinmimbs$date$Pattern$literal, $justinmimbs$date$Pattern$escapedQuote, $justinmimbs$date$Pattern$quoted]))),
				$elm$parser$Parser$lazy(
				function (_v0) {
					return $elm$parser$Parser$succeed(
						$justinmimbs$date$Pattern$finalize(tokens));
				})
			]));
};
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {a4: col, bq: problem, bA: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.bA, p.a4, p.bq);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{a4: 1, c: _List_Nil, f: 1, b: 0, bA: 1, a: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $justinmimbs$date$Pattern$fromString = function (str) {
	return A2(
		$elm$core$Result$withDefault,
		_List_fromArray(
			[
				$justinmimbs$date$Pattern$Literal(str)
			]),
		A2(
			$elm$parser$Parser$run,
			$justinmimbs$date$Pattern$patternHelp(_List_Nil),
			str));
};
var $justinmimbs$date$Date$formatWithLanguage = F2(
	function (language, pattern) {
		var tokens = $elm$core$List$reverse(
			$justinmimbs$date$Pattern$fromString(pattern));
		return A2($justinmimbs$date$Date$formatWithTokens, language, tokens);
	});
var $justinmimbs$date$Date$monthToName = function (m) {
	switch (m) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $justinmimbs$date$Date$weekdayToName = function (wd) {
	switch (wd) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $justinmimbs$date$Date$language_en = {
	aC: $justinmimbs$date$Date$withOrdinalSuffix,
	aL: $justinmimbs$date$Date$monthToName,
	aw: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$monthToName,
		$elm$core$String$left(3)),
	a$: $justinmimbs$date$Date$weekdayToName,
	aa: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$weekdayToName,
		$elm$core$String$left(3))
};
var $justinmimbs$date$Date$format = function (pattern) {
	return A2($justinmimbs$date$Date$formatWithLanguage, $justinmimbs$date$Date$language_en, pattern);
};
var $author$project$DateUtils$Format$isoDate = $justinmimbs$date$Date$format('y-MM-dd');
var $author$project$SearchDatepicker$urlSegment = function (_v0) {
	var currentMonth = _v0.e;
	var selection = _v0.d;
	if (selection.$ === 1) {
		var date = selection.a;
		return A2($elm$core$Maybe$map, $author$project$DateUtils$Format$isoDate, date);
	} else {
		switch (selection.a.$) {
			case 0:
				if (!selection.a.b) {
					var _v2 = selection.a;
					var date = _v2.a;
					var _v3 = _v2.b;
					return $elm$core$Maybe$Just(
						$author$project$DateUtils$Format$isoDate(date));
				} else {
					var _v4 = selection.a;
					var date = _v4.a;
					var _v5 = _v4.b;
					return $elm$core$Maybe$Just(
						$author$project$DateUtils$Format$isoDate(date) + (',' + '1'));
				}
			case 1:
				if (selection.a.a === 1) {
					var _v6 = selection.a.a;
					return $elm$core$Maybe$Just(
						$author$project$DateUtils$Format$isoDate(currentMonth) + (',' + '2'));
				} else {
					var _v7 = selection.a.a;
					return $elm$core$Maybe$Just(
						$author$project$DateUtils$Format$isoDate(currentMonth) + (',' + '3'));
				}
			default:
				var _v8 = selection.a;
				return $elm$core$Maybe$Nothing;
		}
	}
};
var $author$project$Search$Main$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var autocompleteMsg = msg.a;
				return A2($author$project$Search$Main$updateAutocomplete, autocompleteMsg, model);
			case 1:
				var locationMsg = msg.a;
				return A2($author$project$Search$Main$updateLocation, locationMsg, model);
			case 2:
				return _Utils_Tuple2(model, $author$project$Search$Main$getViewport);
			case 3:
				var viewport = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ar: $elm$core$Maybe$Just(viewport)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var count = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ab: $elm$core$Maybe$Just(count)
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var path = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ax: $elm$core$Maybe$Just(path)
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var _v1 = $author$project$Shared$Modal$open(model.O);
				var openedModal = _v1.a;
				var openCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{O: openedModal}),
					openCmd);
			case 7:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 8:
				var _v2 = $author$project$Shared$Modal$close(model.O);
				var closedModal = _v2.a;
				var closeCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{O: closedModal}),
					closeCmd);
			case 9:
				var message = msg.a;
				var _v3 = A2($author$project$SearchDatepicker$update, message, model.r);
				var newDate = _v3.a;
				var cmdMsg = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{r: newDate}),
					A2($elm$core$Platform$Cmd$map, $author$project$Search$Main$DatepickerMsg, cmdMsg));
			case 10:
				var offset = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							x: A2($author$project$Shared$Select$update, offset, model.x)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var lengthOfStaySegment = function () {
					var _v6 = $elm$core$String$uncons(
						$author$project$Shared$Select$toSelectedValue(model.x));
					if ((!_v6.$) && ('/' === _v6.a.a)) {
						var _v7 = _v6.a;
						var slug = _v7.b;
						return $elm$core$Maybe$Just(slug);
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				var _v4 = function () {
					var _v5 = model.g;
					if (!_v5.a.$) {
						var filter = _v5.a.a;
						return _Utils_Tuple2(
							$author$project$LocationFilter$urlSegment(filter),
							$author$project$LocationFilter$isVenue(filter));
					} else {
						var autocomplete = _v5.a.a;
						return _Utils_Tuple2(
							$author$project$Autocomplete$urlSegment(autocomplete),
							$author$project$Autocomplete$isVenue(autocomplete));
					}
				}();
				var locationSegment = _v4.a;
				var isVenueUrl = _v4.b;
				var dateSegment = $author$project$SearchDatepicker$urlSegment(
					isVenueUrl ? $author$project$SearchDatepicker$removeFlexibility(model.r) : model.r);
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Search$Main$search(
								$author$project$Search$Url$generate(
									{
										r: dateSegment,
										aI: A2($elm$core$Maybe$withDefault, '', model.ax),
										b9: lengthOfStaySegment,
										g: locationSegment
									})),
								(!$author$project$Search$Main$isSearchPage(model)) ? $author$project$Ga4$Main$navigationType('search_box') : $elm$core$Platform$Cmd$none
							])));
		}
	});
var $author$project$Search$Main$Submit = {$: 11};
var $elm$html$Html$button = _VirtualDom_node('button');
var $author$project$Ga4$Main$clickAttr = $author$project$Ga4$Main$customClickAttr('click');
var $elm$html$Html$form = _VirtualDom_node('form');
var $author$project$Search$Main$formHeading = 'Find your dream spa break or spa day';
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $author$project$Search$Main$formHeadingView = function (headingClass) {
	return A2(
		$elm$html$Html$h1,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(headingClass + ' utils:text-center')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text($author$project$Search$Main$formHeading)
			]));
};
var $author$project$Search$Main$ga4EventGroup = 'Search Bar';
var $author$project$Search$Main$isHomePage = function (_v0) {
	var flags = _v0.au;
	return !flags.aQ;
};
var $author$project$Utils$CssConstants$searchBreakpoint = 1025;
var $author$project$Search$Main$isMobile = function (_v0) {
	var viewport = _v0.ar;
	return A3(
		$author$project$Utils$Maybe$unwrap,
		false,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.ar;
			},
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.cJ;
				},
				$elm$core$Basics$gt($author$project$Utils$CssConstants$searchBreakpoint))),
		viewport);
};
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $author$project$Autocomplete$isLocationResult = function (_v0) {
	var result = _v0.al;
	var _v1 = result.ay;
	if (_v1.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $author$project$Autocomplete$heading = function (str) {
	return A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$attribute, 'role', 'heading'),
				A2($elm$html$Html$Attributes$attribute, 'aria-selected', 'false'),
				$elm$html$Html$Attributes$tabindex(-1)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onMouseEnter = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseenter',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $author$project$Shared$Events$preventDefault = F2(
	function (customEvent, msg) {
		return A2(
			$elm$html$Html$Events$custom,
			customEvent,
			$elm$json$Json$Decode$succeed(
				{cb: msg, cm: true, cw: true}));
	});
var $author$project$Shared$Events$preventDefaultMouseDown = $author$project$Shared$Events$preventDefault('mousedown');
var $author$project$Autocomplete$resultRow = F2(
	function (highlightedIndex, _v0) {
		var result = _v0.al;
		var index = _v0.aH;
		var onHover = _v0.ad;
		var onSelect = _v0.ae;
		var selected = $author$project$Autocomplete$boolToString(
			A3(
				$author$project$Utils$Maybe$unwrap,
				false,
				$elm$core$Basics$eq(index),
				highlightedIndex));
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$attribute, 'role', 'option'),
					A2($elm$html$Html$Attributes$attribute, 'aria-selected', selected),
					A2(
					$elm$html$Html$Attributes$attribute,
					'aria-posinset',
					$elm$core$String$fromInt(index)),
					$elm$html$Html$Attributes$tabindex(-1),
					$elm$html$Html$Events$onMouseEnter(onHover),
					$author$project$Shared$Events$preventDefaultMouseDown(
					onSelect(result))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(result.A)
				]));
	});
var $author$project$Autocomplete$renderGroup = F3(
	function (highlightedIndex, headingText, results) {
		return $elm$core$List$isEmpty(results) ? _List_Nil : A2(
			$elm$core$List$cons,
			$author$project$Autocomplete$heading(headingText),
			A2(
				$elm$core$List$map,
				$author$project$Autocomplete$resultRow(highlightedIndex),
				results));
	});
var $author$project$Autocomplete$locationsGroup = F2(
	function (highlightedIndex, results) {
		return A3(
			$author$project$Autocomplete$renderGroup,
			highlightedIndex,
			'Places',
			A2($elm$core$List$filter, $author$project$Autocomplete$isLocationResult, results));
	});
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Autocomplete$venuesGroup = F2(
	function (highlightedIndex, results) {
		return A3(
			$author$project$Autocomplete$renderGroup,
			highlightedIndex,
			'Venues',
			A2(
				$elm$core$List$filter,
				A2($elm$core$Basics$composeR, $author$project$Autocomplete$isLocationResult, $elm$core$Basics$not),
				results));
	});
var $author$project$Autocomplete$renderResults = F3(
	function (listBoxId, results, highlightedIndex) {
		return $elm$core$List$isEmpty(results) ? $elm$html$Html$text('') : A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id(listBoxId),
							A2($elm$html$Html$Attributes$attribute, 'role', 'listbox')
						]),
					_Utils_ap(
						A2($author$project$Autocomplete$locationsGroup, highlightedIndex, results),
						A2($author$project$Autocomplete$venuesGroup, highlightedIndex, results))),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-live', 'polite'),
							A2($elm$html$Html$Attributes$attribute, 'role', 'status')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(
								$elm$core$List$length(results)) + ' results available.')
						]))
				]));
	});
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$html$Html$input = _VirtualDom_node('input');
var $author$project$Shared$Input$inputAttribute = function (_v0) {
	var attr = _v0.a;
	var val = _v0.b;
	return A2($elm$html$Html$Attributes$attribute, attr, val);
};
var $author$project$Shared$Input$inputAttributes = function (htmlAttrs) {
	return A2(
		$elm$core$List$map,
		$author$project$Shared$Input$inputAttribute,
		$elm$core$Dict$toList(htmlAttrs));
};
var $author$project$Utils$Html$attributeIf = F2(
	function (condition, attr) {
		return condition ? attr : $author$project$Utils$Html$emptyAttribute;
	});
var $author$project$Utils$Html$attributeIfNotEmpty = F2(
	function (attrFn, value) {
		return A2(
			$author$project$Utils$Html$attributeIf,
			!(value === ''),
			attrFn(value));
	});
var $author$project$Utils$Input$invalidClassAttribute = function (error) {
	return A2(
		$author$project$Utils$Html$attributeIfNotEmpty,
		$elm$core$Basics$always(
			$elm$html$Html$Attributes$class('invalid')),
		error);
};
var $author$project$Shared$Input$labelClasses = function (classes) {
	return _List_fromArray(
		[
			$elm$html$Html$Attributes$classList(
			A2(
				$elm$core$List$cons,
				_Utils_Tuple2('ui-component-input', true),
				classes))
		]);
};
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $author$project$Utils$Html$onBlur = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue));
};
var $author$project$Shared$Events$onWithEvent = F2(
	function (name, toMsg) {
		return A2(
			$elm$html$Html$Events$on,
			name,
			A2($elm$json$Json$Decode$map, toMsg, $elm$json$Json$Decode$value));
	});
var $author$project$Shared$Input$onFocus = $author$project$Shared$Events$onWithEvent('focus');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $author$project$Shared$Input$ArrowDown = 1;
var $author$project$Shared$Input$ArrowUp = 0;
var $author$project$Shared$Input$Enter = 2;
var $author$project$Shared$Input$Escape = 3;
var $author$project$Shared$Input$maybeKey = function (key) {
	switch (key) {
		case 'ArrowUp':
			return $elm$core$Maybe$Just(0);
		case 'ArrowDown':
			return $elm$core$Maybe$Just(1);
		case 'Enter':
			return $elm$core$Maybe$Just(2);
		case 'Escape':
			return $elm$core$Maybe$Just(3);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Shared$Input$arrowKeyDecoder = F2(
	function (callback, keyStr) {
		var _v0 = $author$project$Shared$Input$maybeKey(keyStr);
		if (!_v0.$) {
			var key = _v0.a;
			return $elm$json$Json$Decode$succeed(
				callback(key));
		} else {
			return $elm$json$Json$Decode$fail('Not an arrow key');
		}
	});
var $author$project$Shared$Events$alwaysPrevent = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $author$project$Shared$Events$alwaysPreventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$html$Html$Events$preventDefaultOn,
			event,
			A2($elm$json$Json$Decode$map, $author$project$Shared$Events$alwaysPrevent, decoder));
	});
var $author$project$Shared$Events$decodeKeyAndPreventDefaultOn = F2(
	function (event, keyToDecoderMsg) {
		return A2(
			$author$project$Shared$Events$alwaysPreventDefaultOn,
			event,
			A2(
				$elm$json$Json$Decode$andThen,
				keyToDecoderMsg,
				A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string)));
	});
var $author$project$Shared$Input$onKeyDown = function (callback) {
	return A2(
		$author$project$Shared$Events$decodeKeyAndPreventDefaultOn,
		'keydown',
		$author$project$Shared$Input$arrowKeyDecoder(callback));
};
var $elm$html$Html$Events$onMouseUp = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseup',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Attributes$required = $elm$html$Html$Attributes$boolProperty('required');
var $author$project$Shared$Input$sizeAttr = function (htmlAttrs) {
	return A2(
		$author$project$Utils$Html$attributeMaybe,
		$elm$html$Html$Attributes$attribute('size'),
		A2($elm$core$Dict$get, 'maxlength', htmlAttrs));
};
var $author$project$Utils$Input$errorHtml = function (errorText) {
	if (errorText === '') {
		return $elm$html$Html$text('');
	} else {
		var error = errorText;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('utils:error')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(error)
				]));
	}
};
var $author$project$Utils$Input$hintHtml = function (hintText) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('input-hint')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(hintText)
			]));
};
var $author$project$Utils$Input$subLabelHtml = F2(
	function (hint, error) {
		var _v0 = _Utils_Tuple2(hint, error);
		if (_v0.a === '') {
			if (_v0.b === '') {
				return _List_Nil;
			} else {
				return _List_fromArray(
					[
						$author$project$Utils$Input$errorHtml(error)
					]);
			}
		} else {
			if (_v0.b === '') {
				return _List_fromArray(
					[
						$author$project$Utils$Input$hintHtml(hint)
					]);
			} else {
				return _List_fromArray(
					[
						$author$project$Utils$Input$errorHtml(error),
						$author$project$Utils$Input$hintHtml(hint)
					]);
			}
		}
	});
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Shared$Input$toHtml = function (_v0) {
	var attributes = _v0;
	return A2(
		$elm$html$Html$label,
		$author$project$Shared$Input$labelClasses(attributes.y),
		_Utils_ap(
			_List_fromArray(
				[
					A2($author$project$Utils$Html$maybeRender, $author$project$Shared$Icons$icon, attributes.N),
					A2(
					$elm$html$Html$input,
					_Utils_ap(
						_List_fromArray(
							[
								$author$project$Utils$Input$invalidClassAttribute(attributes.o),
								A2($author$project$Utils$Html$attributeMaybe, $author$project$Utils$Html$onBlur, attributes.cf),
								A2($author$project$Utils$Html$attributeMaybe, $author$project$Shared$Input$onFocus, attributes.cg),
								$elm$html$Html$Events$onInput(attributes.K),
								A2($author$project$Utils$Html$attributeMaybe, $author$project$Shared$Input$onKeyDown, attributes.ch),
								A2($author$project$Utils$Html$attributeMaybe, $elm$html$Html$Events$onMouseUp, attributes.P),
								$elm$html$Html$Attributes$required(attributes.D),
								$elm$html$Html$Attributes$disabled(attributes.F),
								$author$project$Shared$Input$sizeAttr(attributes.k),
								$elm$html$Html$Attributes$value(attributes.j)
							]),
						$author$project$Shared$Input$inputAttributes(attributes.k)),
					_List_Nil),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('input-label')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(attributes.I)
						]))
				]),
			A2($author$project$Utils$Input$subLabelHtml, attributes.H, attributes.o)));
};
var $author$project$Autocomplete$view = function (_v0) {
	var input = _v0.z;
	var listBoxId = _v0.aJ;
	var results = _v0.af;
	var highlightedIndex = _v0.G;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ui-component-autocomplete')
			]),
		_List_fromArray(
			[
				$author$project$Shared$Input$toHtml(input),
				A3($author$project$Autocomplete$renderResults, listBoxId, results, highlightedIndex)
			]));
};
var $miyamoen$select_list$Transform$indexedMap_ = F2(
	function (f, _v0) {
		var before = _v0.a;
		var a = _v0.b;
		var after = _v0.c;
		var targetIndex = $elm$core$List$length(before);
		var newBefore = A2(
			$elm$core$List$indexedMap,
			function (index) {
				return A2(f, false, (targetIndex - 1) - index);
			},
			before);
		var newAfter = A2(
			$elm$core$List$indexedMap,
			function (index) {
				return A2(f, false, (targetIndex + 1) + index);
			},
			after);
		return A2(
			$miyamoen$select_list$Types$reverseAppend,
			newBefore,
			A2(
				$elm$core$List$cons,
				A3(f, true, targetIndex, a),
				newAfter));
	});
var $miyamoen$select_list$SelectList$indexedMap_ = $miyamoen$select_list$Transform$indexedMap_;
var $author$project$LocationFilter$boolToString = function (bool) {
	return bool ? 'true' : 'false';
};
var $author$project$LocationFilter$dividerCheck = F2(
	function (result, selected) {
		if (result.$ === 1) {
			return selected;
		} else {
			return false;
		}
	});
var $author$project$LocationFilter$resultRow = F6(
	function (highlightedIndex, onHover, onSelect, selected, index, result) {
		var highlighted = $author$project$LocationFilter$boolToString(
			_Utils_eq(highlightedIndex, index));
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$attribute, 'role', 'option'),
					A2($elm$html$Html$Attributes$attribute, 'aria-selected', highlighted),
					A2(
					$elm$html$Html$Attributes$attribute,
					'aria-posinset',
					$elm$core$String$fromInt(index)),
					$elm$html$Html$Attributes$tabindex(-1),
					$elm$html$Html$Events$onMouseEnter(
					onHover(index)),
					$author$project$Shared$Events$preventDefaultMouseDown(onSelect),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'divider',
							A2($author$project$LocationFilter$dividerCheck, result, selected))
						]))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$author$project$LocationFilter$resultName(result))
				]));
	});
var $author$project$LocationFilter$renderResultRows = F4(
	function (highlightedIndex, onHover, onSelect, results) {
		return A2(
			$miyamoen$select_list$SelectList$indexedMap_,
			F3(
				function (selected, index, elem) {
					return A6($author$project$LocationFilter$resultRow, highlightedIndex, onHover, onSelect, selected, index, elem);
				}),
			results);
	});
var $author$project$LocationFilter$renderResults = function (_v0) {
	var listBoxId = _v0.aJ;
	var onHover = _v0.ad;
	var onSelect = _v0.ae;
	var results = _v0.af;
	var highlightedIndex = _v0.G;
	var state = _v0.S;
	if (!state) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id(listBoxId),
							A2($elm$html$Html$Attributes$attribute, 'role', 'listbox')
						]),
					A4($author$project$LocationFilter$renderResultRows, highlightedIndex, onHover, onSelect, results)),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-live', 'polite'),
							A2($elm$html$Html$Attributes$attribute, 'role', 'status')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(
								$author$project$LocationFilter$resultCount(results)) + ' results available.')
						]))
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$LocationFilter$view = function (_v0) {
	var filter = _v0;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ui-component-autocomplete')
			]),
		_List_fromArray(
			[
				$author$project$Shared$Input$toHtml(filter.z),
				$author$project$LocationFilter$renderResults(filter)
			]));
};
var $author$project$Search$Main$locationView = function (_v0) {
	var location = _v0.g;
	if (!location.a.$) {
		var filter = location.a.a;
		return $author$project$LocationFilter$view(filter);
	} else {
		var autocomplete = location.a.a;
		return $author$project$Autocomplete$view(autocomplete);
	}
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$Shared$Events$preventDefaultClick = $author$project$Shared$Events$preventDefault('click');
var $author$project$Shared$Select$labelAttributes = function (_v0) {
	var attributes = _v0;
	return _List_fromArray(
		[
			$elm$html$Html$Attributes$classList(
			A2(
				$elm$core$List$cons,
				_Utils_Tuple2('required', attributes.D),
				attributes.y))
		]);
};
var $miyamoen$select_list$SelectList$Selected = 1;
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $author$project$Shared$Select$optionHtml = F3(
	function (index, position, options) {
		var selectedOption = $miyamoen$select_list$SelectList$selected(options);
		var isDisabled = selectedOption.F;
		var val = isDisabled ? '' : $elm$core$String$fromInt(
			$miyamoen$select_list$SelectList$index(options) - index);
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$attribute, 'value', val),
					$elm$html$Html$Attributes$selected(position === 1),
					$elm$html$Html$Attributes$disabled(isDisabled)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(selectedOption.A)
				]));
	});
var $elm$html$Html$select = _VirtualDom_node('select');
var $miyamoen$select_list$SelectList$AfterSelected = 2;
var $miyamoen$select_list$SelectList$BeforeSelected = 0;
var $miyamoen$select_list$SelectList$convert = function (position) {
	switch (position) {
		case 0:
			return 0;
		case 1:
			return 1;
		default:
			return 2;
	}
};
var $miyamoen$select_list$Transform$AfterSelected = 2;
var $miyamoen$select_list$Transform$BeforeSelected = 0;
var $miyamoen$select_list$Transform$Selected = 1;
var $miyamoen$select_list$Select$allAfter = function (_v0) {
	var before = _v0.a;
	var a = _v0.b;
	var after = _v0.c;
	if (after.b) {
		var x = after.a;
		var xs = after.b;
		var next = A3(
			$miyamoen$select_list$Types$SelectList,
			A2($elm$core$List$cons, a, before),
			x,
			xs);
		return A2(
			$elm$core$List$cons,
			next,
			$miyamoen$select_list$Select$allAfter(next));
	} else {
		return _List_Nil;
	}
};
var $miyamoen$select_list$Select$allBeforeHelp = function (_v0) {
	var before = _v0.a;
	var a = _v0.b;
	var after = _v0.c;
	if (before.b) {
		var x = before.a;
		var xs = before.b;
		var next = A3(
			$miyamoen$select_list$Types$SelectList,
			xs,
			x,
			A2($elm$core$List$cons, a, after));
		return A2(
			$elm$core$List$cons,
			next,
			$miyamoen$select_list$Select$allBeforeHelp(next));
	} else {
		return _List_Nil;
	}
};
var $miyamoen$select_list$Transform$selectedMap = F2(
	function (f, list) {
		var before = A2(
			$elm$core$List$map,
			f(0),
			$miyamoen$select_list$Select$allBeforeHelp(list));
		var after = A2(
			$elm$core$List$map,
			f(2),
			$miyamoen$select_list$Select$allAfter(list));
		return A2(
			$miyamoen$select_list$Types$reverseAppend,
			before,
			A2(
				$elm$core$List$cons,
				A2(f, 1, list),
				after));
	});
var $miyamoen$select_list$SelectList$selectedMap = function (f) {
	return $miyamoen$select_list$Transform$selectedMap(
		F2(
			function (pos, list) {
				return A2(
					f,
					$miyamoen$select_list$SelectList$convert(pos),
					list);
			}));
};
var $author$project$Shared$Select$toHtml = function (_v0) {
	var attributes = _v0;
	return A2(
		$elm$html$Html$label,
		$author$project$Shared$Select$labelAttributes(attributes),
		_Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('input-label')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(attributes.I)
						])),
					A2($author$project$Utils$Html$maybeRender, $author$project$Shared$Icons$icon, attributes.N),
					A2(
					$elm$html$Html$select,
					_List_fromArray(
						[
							$elm$html$Html$Events$onInput(attributes.K),
							A2($author$project$Utils$Html$attributeIfNotEmpty, $elm$html$Html$Attributes$id, attributes.aG),
							$author$project$Utils$Input$invalidClassAttribute(attributes.o),
							$elm$html$Html$Attributes$required(attributes.D)
						]),
					A2(
						$miyamoen$select_list$SelectList$selectedMap,
						$author$project$Shared$Select$optionHtml(
							$miyamoen$select_list$SelectList$index(attributes.q)),
						attributes.q)),
					$author$project$Shared$Icons$icon('fa-chevron-down fa-fw')
				]),
			A2($author$project$Utils$Input$subLabelHtml, attributes.H, attributes.o)));
};
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $author$project$Search$Main$trustpilotView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('trustpilot-widget'),
			A2($elm$html$Html$Attributes$attribute, 'data-locale', 'en-GB'),
			A2($elm$html$Html$Attributes$attribute, 'data-template-id', '5419b6ffb0d04a076446a9af'),
			A2($elm$html$Html$Attributes$attribute, 'data-businessunit-id', '4bdc20440000640005058ea1'),
			A2($elm$html$Html$Attributes$attribute, 'data-style-height', '20px'),
			A2($elm$html$Html$Attributes$attribute, 'data-style-width', '100%'),
			A2($elm$html$Html$Attributes$attribute, 'data-theme', 'light')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$href('https://uk.trustpilot.com/review/www.spabreaks.com'),
					$elm$html$Html$Attributes$target('_blank')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Trustpilot')
				]))
		]));
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$SearchDatepicker$Blur = {$: 1};
var $author$project$SearchDatepicker$DoNothing = {$: 12};
var $author$project$SearchDatepicker$Focus = {$: 0};
var $author$project$SearchDatepicker$Update = {$: 8};
var $elm$html$Html$table = _VirtualDom_node('table');
var $author$project$SearchDatepicker$dayText = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$day,
	A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$html$Html$text));
var $elm$html$Html$td = _VirtualDom_node('td');
var $author$project$SearchDatepicker$pastDay = function (date) {
	return A2(
		$elm$html$Html$td,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('day past')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$SearchDatepicker$dayText(date)
					]))
			]));
};
var $author$project$SearchDatepicker$HighlightDate = function (a) {
	return {$: 5, a: a};
};
var $author$project$SearchDatepicker$RemoveHighlight = {$: 6};
var $author$project$SearchDatepicker$SelectDate = function (a) {
	return {$: 4, a: a};
};
var $elm$html$Html$Events$onMouseLeave = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseleave',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$SearchDatepicker$selectableDay = function (_v0) {
	var selected = _v0.aW;
	var flexible = _v0.aE;
	var highlighted = _v0.aF;
	var otherMonth = _v0.aO;
	var date = _v0.r;
	return A2(
		$elm$html$Html$td,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('day', true),
						_Utils_Tuple2('selected', selected),
						_Utils_Tuple2('flexible', flexible),
						_Utils_Tuple2('highlighted', highlighted),
						_Utils_Tuple2('other-month', otherMonth)
					])),
				$author$project$Shared$Events$preventDefaultClick(
				$author$project$SearchDatepicker$SelectDate(date)),
				$elm$html$Html$Events$onMouseEnter(
				$author$project$SearchDatepicker$HighlightDate(date)),
				$elm$html$Html$Events$onMouseLeave($author$project$SearchDatepicker$RemoveHighlight)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$SearchDatepicker$dayText(date)
					]))
			]));
};
var $author$project$SearchDatepicker$tableDay = function (cell) {
	if (!cell.$) {
		var pastDateCell = cell.a;
		return $author$project$SearchDatepicker$pastDay(pastDateCell);
	} else {
		var selectableCell = cell.a;
		return $author$project$SearchDatepicker$selectableDay(selectableCell);
	}
};
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $author$project$SearchDatepicker$tableRow = function (row) {
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		A2($elm$core$List$map, $author$project$SearchDatepicker$tableDay, row));
};
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $author$project$SearchDatepicker$weekdayHeadingCell = function (label) {
	return A2(
		$elm$html$Html$td,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('weekday')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(label)
			]));
};
var $author$project$Utils$Calendar$weekdayLabels = _List_fromArray(
	['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']);
var $author$project$SearchDatepicker$calendar = function (rows) {
	return A2(
		$elm$html$Html$table,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('table')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$thead,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						A2($elm$core$List$map, $author$project$SearchDatepicker$weekdayHeadingCell, $author$project$Utils$Calendar$weekdayLabels))
					])),
				A2(
				$elm$html$Html$tbody,
				_List_Nil,
				A2($elm$core$List$map, $author$project$SearchDatepicker$tableRow, rows))
			]));
};
var $author$project$SearchDatepicker$Clear = {$: 7};
var $elm$html$Html$Events$onMouseDown = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mousedown',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$SearchDatepicker$clearButton = A2(
	$elm$html$Html$button,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('ui-component-button variant:link'),
			$elm$html$Html$Events$onMouseDown($author$project$SearchDatepicker$Clear)
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('Clear')
		]));
var $author$project$SearchDatepicker$Multi = function (a) {
	return {$: 1, a: a};
};
var $author$project$SearchDatepicker$Single = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$fieldset = _VirtualDom_node('fieldset');
var $author$project$SearchDatepicker$FlexibilityChanged = function (a) {
	return {$: 9, a: a};
};
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $author$project$SearchDatepicker$flexibleDays = 3;
var $author$project$SearchDatepicker$flexibilityLabelText = F2(
	function (flexibility, month) {
		var label = $author$project$SearchDatepicker$flexibilityToString(flexibility);
		if (!flexibility.$) {
			if (!flexibility.a) {
				var _v1 = flexibility.a;
				return label;
			} else {
				var _v2 = flexibility.a;
				return label + (' (+/- ' + ($elm$core$String$fromInt($author$project$SearchDatepicker$flexibleDays) + ' days)'));
			}
		} else {
			if (!flexibility.a) {
				var _v3 = flexibility.a;
				return label + (' in ' + month);
			} else {
				var _v4 = flexibility.a;
				return label + (' in ' + month);
			}
		}
	});
var $author$project$SearchDatepicker$isFlexibilityChecked = F2(
	function (selection, flexibility) {
		var _v0 = _Utils_Tuple2(selection, flexibility);
		_v0$3:
		while (true) {
			if (_v0.a.$ === 1) {
				return false;
			} else {
				if (!_v0.b.$) {
					if (!_v0.a.a.$) {
						var _v1 = _v0.a.a;
						var selectedFlexibility = _v1.b;
						var currentFlexibility = _v0.b.a;
						return _Utils_eq(selectedFlexibility, currentFlexibility);
					} else {
						break _v0$3;
					}
				} else {
					if (_v0.a.a.$ === 1) {
						var selectedFlexibility = _v0.a.a.a;
						var currentFlexibility = _v0.b.a;
						return _Utils_eq(selectedFlexibility, currentFlexibility);
					} else {
						break _v0$3;
					}
				}
			}
		}
		return false;
	});
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $author$project$SearchDatepicker$flexibilityRadioView = F2(
	function (viewModel, flexibility) {
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui-component-radio-button'),
					$author$project$Shared$Events$preventDefaultClick(
					$author$project$SearchDatepicker$FlexibilityChanged(flexibility))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('radio'),
							$elm$html$Html$Attributes$name('flexible_dates'),
							$elm$html$Html$Attributes$checked(
							A2($author$project$SearchDatepicker$isFlexibilityChecked, viewModel.d, flexibility))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							A2(
								$author$project$SearchDatepicker$flexibilityLabelText,
								flexibility,
								A2($justinmimbs$date$Date$format, 'MMMM', viewModel.e)))
						]))
				]));
	});
var $author$project$SearchDatepicker$flexibleChoices = function (viewModel) {
	var _v0 = viewModel.d;
	if (_v0.$ === 1) {
		return $elm$html$Html$text('');
	} else {
		var flexible = _v0.a;
		return A2(
			$elm$html$Html$fieldset,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui-component-radio-group')
				]),
			A2(
				$elm$core$List$map,
				$author$project$SearchDatepicker$flexibilityRadioView(viewModel),
				function () {
					if (!flexible.$) {
						return _List_fromArray(
							[
								$author$project$SearchDatepicker$Single(0),
								$author$project$SearchDatepicker$Single(1),
								$author$project$SearchDatepicker$Multi(0),
								$author$project$SearchDatepicker$Multi(1)
							]);
					} else {
						return _List_fromArray(
							[
								$author$project$SearchDatepicker$Multi(0),
								$author$project$SearchDatepicker$Multi(1)
							]);
					}
				}()));
	}
};
var $author$project$SearchDatepicker$flexibleView = function (viewModel) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('h4')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Flexible arrival options')
					])),
				$author$project$SearchDatepicker$flexibleChoices(viewModel)
			]));
};
var $author$project$DateUtils$Format$longMonthAndYear = $justinmimbs$date$Date$format('MMMM y');
var $author$project$SearchDatepicker$NextMonth = {$: 3};
var $author$project$SearchDatepicker$nextLink = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('next'),
			$author$project$Shared$Events$preventDefaultClick($author$project$SearchDatepicker$NextMonth)
		]),
	_List_fromArray(
		[
			$author$project$Shared$Icons$icon('fa-fw fa-chevron-right')
		]));
var $author$project$SearchDatepicker$PrevMonth = {$: 2};
var $author$project$SearchDatepicker$prevLink = function (enabled) {
	return enabled ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('prev'),
				$author$project$Shared$Events$preventDefaultClick($author$project$SearchDatepicker$PrevMonth)
			]),
		_List_fromArray(
			[
				$author$project$Shared$Icons$icon('fa-fw fa-chevron-left')
			])) : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('prev disabled')
			]),
		_List_fromArray(
			[
				$author$project$Shared$Icons$icon('fa-fw fa-chevron-left')
			]));
};
var $author$project$SearchDatepicker$monthSwitcher = function (_v0) {
	var renderPrevMonth = _v0.aU;
	var currentMonth = _v0.e;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('controls')
			]),
		_List_fromArray(
			[
				$author$project$SearchDatepicker$prevLink(renderPrevMonth),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$DateUtils$Format$longMonthAndYear(currentMonth))
					])),
				$author$project$SearchDatepicker$nextLink
			]));
};
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$SearchDatepicker$positionClass = function (position) {
	switch (position) {
		case 0:
			return 'sit-left';
		case 1:
			return 'sit-right';
		default:
			return '';
	}
};
var $author$project$SearchDatepicker$updateButton = A2(
	$elm$html$Html$button,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('ui-component-button variant:outline'),
			$elm$html$Html$Events$onMouseDown($author$project$SearchDatepicker$Update)
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('Update')
		]));
var $author$project$SearchDatepicker$datePicker = function (viewModel) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(
				$author$project$SearchDatepicker$pickerClass + (' elm-datepicker--search ' + $author$project$SearchDatepicker$positionClass(viewModel.R))),
				$author$project$Shared$Events$preventDefaultMouseDown($author$project$SearchDatepicker$DoNothing)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('button dismiss-button'),
						$elm$html$Html$Attributes$type_('button'),
						$elm$html$Html$Events$onClick($author$project$SearchDatepicker$Update)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('sr-only')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Close')
							]))
					])),
				$author$project$SearchDatepicker$monthSwitcher(viewModel),
				$author$project$SearchDatepicker$calendar(viewModel.aV),
				function () {
				var _v0 = viewModel.d;
				if (!_v0.$) {
					return $author$project$SearchDatepicker$flexibleView(viewModel);
				} else {
					return $elm$html$Html$text('');
				}
			}(),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('action-buttons')
					]),
				_List_fromArray(
					[$author$project$SearchDatepicker$clearButton, $author$project$SearchDatepicker$updateButton]))
			]));
};
var $justinmimbs$date$Date$clamp = F3(
	function (dateA, dateB, dateX) {
		var a = dateA;
		var b = dateB;
		var x = dateX;
		return (_Utils_cmp(x, a) < 0) ? dateA : ((_Utils_cmp(b, x) < 0) ? dateB : dateX);
	});
var $author$project$SearchDatepicker$flexibleDateBounds = F2(
	function (minDate, date) {
		return _Utils_Tuple2(
			A3(
				$justinmimbs$date$Date$clamp,
				minDate,
				date,
				A3($justinmimbs$date$Date$add, 3, -$author$project$SearchDatepicker$flexibleDays, date)),
			A3($justinmimbs$date$Date$add, 3, $author$project$SearchDatepicker$flexibleDays, date));
	});
var $author$project$SearchDatepicker$displayValue = function (_v0) {
	var tomorrow = _v0.T;
	var currentMonth = _v0.e;
	var selection = _v0.d;
	if (selection.$ === 1) {
		var date = selection.a;
		return A3(
			$author$project$Utils$Maybe$unwrap,
			'Any date',
			$justinmimbs$date$Date$format('d MMMM'),
			date);
	} else {
		switch (selection.a.$) {
			case 0:
				if (!selection.a.b) {
					var _v2 = selection.a;
					var date = _v2.a;
					var _v3 = _v2.b;
					return A2($justinmimbs$date$Date$format, 'd MMMM', date);
				} else {
					var _v4 = selection.a;
					var date = _v4.a;
					var _v5 = _v4.b;
					return A2(
						$elm$core$String$join,
						' - ',
						A2(
							$elm$core$List$map,
							$justinmimbs$date$Date$format('d MMM'),
							$author$project$Utils$List$fromTuple(
								A2($author$project$SearchDatepicker$flexibleDateBounds, tomorrow, date))));
				}
			case 1:
				if (!selection.a.a) {
					var _v6 = selection.a.a;
					return 'Weekends in ' + A2($justinmimbs$date$Date$format, 'MMMM', currentMonth);
				} else {
					var _v7 = selection.a.a;
					return 'Weekdays in ' + A2($justinmimbs$date$Date$format, 'MMMM', currentMonth);
				}
			default:
				var _v8 = selection.a;
				return 'Any date';
		}
	}
};
var $justinmimbs$date$Date$Month = 2;
var $justinmimbs$date$Date$compare = F2(
	function (_v0, _v1) {
		var a = _v0;
		var b = _v1;
		return A2($elm$core$Basics$compare, a, b);
	});
var $justinmimbs$date$Date$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$date$Date$daysSincePreviousWeekday = F2(
	function (wd, date) {
		return A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$date$Date$weekdayNumber(date) + 7) - $justinmimbs$date$Date$weekdayToNumber(wd));
	});
var $justinmimbs$date$Date$firstOfMonth = F2(
	function (y, m) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + 1;
	});
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$daysBeforeYear(y) + 1;
};
var $justinmimbs$date$Date$quarterToMonth = function (q) {
	return $justinmimbs$date$Date$numberToMonth((q * 3) - 2);
};
var $justinmimbs$date$Date$floor = F2(
	function (interval, date) {
		var rd = date;
		switch (interval) {
			case 0:
				return $justinmimbs$date$Date$firstOfYear(
					$justinmimbs$date$Date$year(date));
			case 1:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$quarterToMonth(
						$justinmimbs$date$Date$quarter(date)));
			case 2:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$month(date));
			case 3:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 4:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 5:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 1, date);
			case 6:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 2, date);
			case 7:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 3, date);
			case 8:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 4, date);
			case 9:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 5, date);
			case 10:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 6, date);
			default:
				return date;
		}
	});
var $justinmimbs$date$Date$Day = 11;
var $justinmimbs$date$Date$Monday = 4;
var $justinmimbs$date$Date$Weeks = 2;
var $author$project$Utils$Calendar$buildRows = function (list) {
	var _v0 = A2($elm$core$List$take, 7, list);
	if (!_v0.b) {
		return _List_Nil;
	} else {
		var listHead = _v0;
		return A2(
			$elm$core$List$cons,
			listHead,
			$author$project$Utils$Calendar$buildRows(
				A2($elm$core$List$drop, 7, list)));
	}
};
var $justinmimbs$date$Date$Years = 0;
var $justinmimbs$date$Date$intervalToUnits = function (interval) {
	switch (interval) {
		case 0:
			return _Utils_Tuple2(1, 0);
		case 1:
			return _Utils_Tuple2(3, 1);
		case 2:
			return _Utils_Tuple2(1, 1);
		case 11:
			return _Utils_Tuple2(1, 3);
		default:
			var week = interval;
			return _Utils_Tuple2(1, 2);
	}
};
var $justinmimbs$date$Date$ceiling = F2(
	function (interval, date) {
		var floored = A2($justinmimbs$date$Date$floor, interval, date);
		if (_Utils_eq(date, floored)) {
			return date;
		} else {
			var _v0 = $justinmimbs$date$Date$intervalToUnits(interval);
			var n = _v0.a;
			var unit = _v0.b;
			return A3($justinmimbs$date$Date$add, unit, n, floored);
		}
	});
var $justinmimbs$date$Date$rangeHelp = F5(
	function (unit, step, until, revList, current) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(current, until) < 0) {
				var _v0 = A3($justinmimbs$date$Date$add, unit, step, current);
				var next = _v0;
				var $temp$unit = unit,
					$temp$step = step,
					$temp$until = until,
					$temp$revList = A2($elm$core$List$cons, current, revList),
					$temp$current = next;
				unit = $temp$unit;
				step = $temp$step;
				until = $temp$until;
				revList = $temp$revList;
				current = $temp$current;
				continue rangeHelp;
			} else {
				return $elm$core$List$reverse(revList);
			}
		}
	});
var $justinmimbs$date$Date$range = F4(
	function (interval, step, _v0, _v1) {
		var start = _v0;
		var until = _v1;
		var _v2 = $justinmimbs$date$Date$intervalToUnits(interval);
		var n = _v2.a;
		var unit = _v2.b;
		var _v3 = A2($justinmimbs$date$Date$ceiling, interval, start);
		var first = _v3;
		return (_Utils_cmp(first, until) < 0) ? A5(
			$justinmimbs$date$Date$rangeHelp,
			unit,
			A2($elm$core$Basics$max, 1, step) * n,
			until,
			_List_Nil,
			first) : _List_Nil;
	});
var $author$project$Utils$Calendar$generateMonth = function (date) {
	var firstRendered = A2(
		$justinmimbs$date$Date$floor,
		4,
		A2($justinmimbs$date$Date$floor, 2, date));
	var firstNotRendered = A3(
		$justinmimbs$date$Date$add,
		2,
		1,
		A2(
			$justinmimbs$date$Date$floor,
			4,
			A2(
				$justinmimbs$date$Date$floor,
				2,
				A3($justinmimbs$date$Date$add, 1, 1, date))));
	return $author$project$Utils$Calendar$buildRows(
		A4($justinmimbs$date$Date$range, 11, 1, firstRendered, firstNotRendered));
};
var $author$project$SearchDatepicker$Past = function (a) {
	return {$: 0, a: a};
};
var $author$project$SearchDatepicker$Selectable = function (a) {
	return {$: 1, a: a};
};
var $author$project$DateUtils$Compare$isBefore = F2(
	function (date1, date2) {
		return !A2($justinmimbs$date$Date$compare, date1, date2);
	});
var $justinmimbs$date$Date$isBetweenInt = F3(
	function (a, b, x) {
		return (_Utils_cmp(a, x) < 1) && (_Utils_cmp(x, b) < 1);
	});
var $justinmimbs$date$Date$isBetween = F3(
	function (_v0, _v1, _v2) {
		var a = _v0;
		var b = _v1;
		var x = _v2;
		return A3($justinmimbs$date$Date$isBetweenInt, a, b, x);
	});
var $author$project$SearchDatepicker$isDateInFlexibleRange = F2(
	function (date, _v0) {
		var first = _v0.a;
		var last = _v0.b;
		return A3($justinmimbs$date$Date$isBetween, first, last, date);
	});
var $author$project$DateUtils$Compare$isWeekend = function (date) {
	return $justinmimbs$date$Date$weekdayNumber(date) >= 5;
};
var $author$project$DateUtils$Compare$isWeekDay = A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$DateUtils$Compare$isWeekend);
var $author$project$SearchDatepicker$isCellFlexible = F3(
	function (_v0, current, otherMonth) {
		var tomorrow = _v0.T;
		var selection = _v0.d;
		_v1$4:
		while (true) {
			if (selection.$ === 1) {
				return false;
			} else {
				switch (selection.a.$) {
					case 0:
						if (selection.a.b === 1) {
							var _v2 = selection.a;
							var date = _v2.a;
							var _v3 = _v2.b;
							return A2(
								$author$project$SearchDatepicker$isDateInFlexibleRange,
								current,
								A2($author$project$SearchDatepicker$flexibleDateBounds, tomorrow, date));
						} else {
							break _v1$4;
						}
					case 1:
						if (!selection.a.a) {
							var _v4 = selection.a.a;
							return $author$project$DateUtils$Compare$isWeekend(current) && (!otherMonth);
						} else {
							var _v5 = selection.a.a;
							return $author$project$DateUtils$Compare$isWeekDay(current) && (!otherMonth);
						}
					default:
						break _v1$4;
				}
			}
		}
		return false;
	});
var $author$project$DateUtils$Compare$isSame = F2(
	function (date1, date2) {
		return A2($justinmimbs$date$Date$compare, date1, date2) === 1;
	});
var $author$project$SearchDatepicker$isCellSelected = F2(
	function (_v0, current) {
		var selection = _v0.d;
		if (selection.$ === 1) {
			var date = selection.a;
			return A3(
				$author$project$Utils$Maybe$unwrap,
				false,
				$author$project$DateUtils$Compare$isSame(current),
				date);
		} else {
			if (!selection.a.$) {
				var _v2 = selection.a;
				var date = _v2.a;
				return A2($author$project$DateUtils$Compare$isSame, current, date);
			} else {
				return false;
			}
		}
	});
var $author$project$DateUtils$Compare$isOtherMonth = F2(
	function (date, otherDate) {
		return !_Utils_eq(
			$justinmimbs$date$Date$month(date),
			$justinmimbs$date$Date$month(otherDate));
	});
var $author$project$SearchDatepicker$isSameMaybeDate = F2(
	function (maybeDate, date) {
		if (maybeDate.$ === 1) {
			return false;
		} else {
			var otherDate = maybeDate.a;
			return A2($author$project$DateUtils$Compare$isSame, otherDate, date);
		}
	});
var $author$project$SearchDatepicker$calendarCell = F2(
	function (model, date) {
		if (A2($author$project$DateUtils$Compare$isBefore, date, model.T)) {
			return $author$project$SearchDatepicker$Past(date);
		} else {
			var otherMonth = A2($author$project$DateUtils$Compare$isOtherMonth, date, model.e);
			return $author$project$SearchDatepicker$Selectable(
				{
					r: date,
					aE: A3($author$project$SearchDatepicker$isCellFlexible, model, date, otherMonth),
					aF: A2($author$project$SearchDatepicker$isSameMaybeDate, model.M, date),
					aO: otherMonth,
					aW: A2($author$project$SearchDatepicker$isCellSelected, model, date)
				});
		}
	});
var $author$project$SearchDatepicker$generateRows = function (model) {
	return $elm$core$List$map(
		$elm$core$List$map(
			$author$project$SearchDatepicker$calendarCell(model)));
};
var $author$project$SearchDatepicker$datepickerSelector = function (model) {
	var rows = A2(
		$author$project$SearchDatepicker$generateRows,
		model,
		$author$project$Utils$Calendar$generateMonth(model.e));
	var renderPrevMonth = !A2(
		$justinmimbs$date$Date$compare,
		A2($justinmimbs$date$Date$floor, 2, model.T),
		A2($justinmimbs$date$Date$floor, 2, model.e));
	return {e: model.e, M: model.M, R: model.R, aU: renderPrevMonth, aV: rows, d: model.d};
};
var $author$project$SearchDatepicker$maybeDatepickerSelector = function (model) {
	var _v0 = model.S;
	if (_v0 === 1) {
		return $elm$core$Maybe$Nothing;
	} else {
		return $elm$core$Maybe$Just(
			$author$project$SearchDatepicker$datepickerSelector(model));
	}
};
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$SearchDatepicker$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('text'),
						$elm$html$Html$Events$onFocus($author$project$SearchDatepicker$Focus),
						$elm$html$Html$Events$onBlur($author$project$SearchDatepicker$Blur),
						$elm$html$Html$Events$onClick($author$project$SearchDatepicker$Focus),
						$elm$html$Html$Events$onInput(
						$elm$core$Basics$always($author$project$SearchDatepicker$DoNothing)),
						$elm$html$Html$Attributes$value(
						$author$project$SearchDatepicker$displayValue(model))
					]),
				_List_Nil),
				A2(
				$author$project$Utils$Html$maybeRender,
				$author$project$SearchDatepicker$datePicker,
				$author$project$SearchDatepicker$maybeDatepickerSelector(model))
			]));
};
var $author$project$Shared$Select$withClass = F2(
	function (_class, _v0) {
		var attributes = _v0;
		return _Utils_update(
			attributes,
			{
				y: A2(
					$elm$core$List$cons,
					_Utils_Tuple2(_class, true),
					attributes.y)
			});
	});
var $author$project$Shared$Select$withIcon = F2(
	function (icon, _v0) {
		var attributes = _v0;
		return _Utils_update(
			attributes,
			{
				N: $elm$core$Maybe$Just(icon)
			});
	});
var $author$project$Search$Main$formView = function (model) {
	return A2(
		$elm$html$Html$form,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ui-component-form')
			]),
		_List_fromArray(
			[
				A2(
				$author$project$Utils$Html$viewIf,
				$author$project$Search$Main$isMobile(model) && $author$project$Search$Main$isHomePage(model),
				$author$project$Search$Main$formHeadingView('h3')),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('search-location')
					]),
				_List_fromArray(
					[
						$author$project$Search$Main$locationView(model)
					])),
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('ui-component-input icon:prefix variant:placeholder-label')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('input-label')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Date')
							])),
						$author$project$Shared$Icons$icon('fa-calendar-alt fa-fw'),
						A2(
						$elm$html$Html$map,
						$author$project$Search$Main$DatepickerMsg,
						$author$project$SearchDatepicker$view(model.r))
					])),
				$author$project$Shared$Select$toHtml(
				A2(
					$author$project$Shared$Select$withClass,
					'label:hidden',
					A2($author$project$Shared$Select$withIcon, 'fa-bed fa-fw', model.x))),
				A2(
				$elm$html$Html$button,
				_Utils_ap(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ui-component-button'),
							$elm$html$Html$Attributes$type_('submit'),
							$author$project$Shared$Events$preventDefaultClick($author$project$Search$Main$Submit)
						]),
					$author$project$Ga4$Main$clickAttr(
						_List_fromArray(
							[
								$author$project$Ga4$Main$eventGroup($author$project$Search$Main$ga4EventGroup)
							]))),
				_List_fromArray(
					[
						$elm$html$Html$text('Search')
					])),
				A2(
				$author$project$Utils$Html$viewIf,
				$author$project$Search$Main$isHomePage(model),
				$author$project$Search$Main$trustpilotView)
			]));
};
var $author$project$Search$Main$desktopView = $author$project$Search$Main$formView;
var $author$project$Search$Main$isDesktop = A2($elm$core$Basics$composeR, $author$project$Search$Main$isMobile, $elm$core$Basics$not);
var $author$project$Search$Main$homeView = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$Utils$Html$viewIf,
				$author$project$Search$Main$isDesktop(model),
				$author$project$Search$Main$formHeadingView('h2')),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Search$Main$formView(model)
					]))
			]));
};
var $author$project$Search$Main$OpenModal = {$: 6};
var $author$project$Shared$Icons$iconLight = $author$project$Shared$Icons$view('fal');
var $elm$html$Html$sup = _VirtualDom_node('sup');
var $author$project$Search$Main$filtersLink = function (appliedCount) {
	return A2(
		$elm$html$Html$button,
		_Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ui-component-button variant:link icon:left'),
					A2($elm$html$Html$Attributes$attribute, 'data-controller', 'filters-modal-trigger'),
					A2($elm$html$Html$Attributes$attribute, 'data-action', 'filters-modal-trigger#openModal')
				]),
			$author$project$Ga4$Main$clickAttr(
				_List_fromArray(
					[
						$author$project$Ga4$Main$eventGroup($author$project$Search$Main$ga4EventGroup),
						$author$project$Ga4$Main$clickTarget('Filters slideout')
					]))),
		_List_fromArray(
			[
				$author$project$Shared$Icons$iconLight('fa-fw fa-sliders-v'),
				$elm$html$Html$text('Filters'),
				A2(
				$author$project$Utils$Html$viewIf,
				appliedCount > 0,
				A2(
					$elm$html$Html$sup,
					_List_Nil,
					$elm$core$List$singleton(
						$elm$html$Html$text(
							$elm$core$String$fromInt(appliedCount)))))
			]));
};
var $miyamoen$select_list$Query$isHead = function (_v0) {
	var before = _v0.a;
	return $elm$core$List$isEmpty(before);
};
var $miyamoen$select_list$SelectList$isHead = $miyamoen$select_list$Query$isHead;
var $author$project$Shared$Select$isFirstSelected = function (_v0) {
	var attributes = _v0;
	return $miyamoen$select_list$SelectList$isHead(attributes.q);
};
var $author$project$Shared$Select$toSelectedName = function (_v0) {
	var attributes = _v0;
	return $miyamoen$select_list$SelectList$selected(attributes.q).A;
};
var $author$project$Search$Main$dateSummaryView = function (_v0) {
	var date = _v0.r;
	var lengthsOfStay = _v0.x;
	var lengthOfStayText = $author$project$Shared$Select$toSelectedName(lengthsOfStay);
	var dateText = $author$project$SearchDatepicker$displayValue(date);
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('utils:text-small,text-truncate')
			]),
		_List_fromArray(
			[
				$author$project$Shared$Select$isFirstSelected(lengthsOfStay) ? $elm$html$Html$text(dateText) : $elm$html$Html$text(lengthOfStayText + (', ' + dateText))
			]));
};
var $author$project$Autocomplete$isEmpty = function (_v0) {
	var autocomplete = _v0;
	var _v1 = autocomplete.Z;
	if (!_v1.$) {
		return false;
	} else {
		return true;
	}
};
var $author$project$LocationFilter$isEmpty = function (_v0) {
	var filter = _v0;
	var _v1 = $miyamoen$select_list$SelectList$selected(filter.af);
	if (!_v1.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Search$Main$isLocationEmpty = function (_v0) {
	var input = _v0.a;
	if (!input.$) {
		var filter = input.a;
		return $author$project$LocationFilter$isEmpty(filter);
	} else {
		var autocomplete = input.a;
		return $author$project$Autocomplete$isEmpty(autocomplete);
	}
};
var $author$project$SearchDatepicker$isNothingSelected = function (_v0) {
	var selection = _v0.d;
	_v1$2:
	while (true) {
		if (selection.$ === 1) {
			if (selection.a.$ === 1) {
				var _v2 = selection.a;
				return true;
			} else {
				break _v1$2;
			}
		} else {
			if (selection.a.$ === 2) {
				var _v3 = selection.a;
				return true;
			} else {
				break _v1$2;
			}
		}
	}
	return false;
};
var $author$project$Search$Main$isEmptyForm = function (_v0) {
	var date = _v0.r;
	var lengthsOfStay = _v0.x;
	var location = _v0.g;
	return $author$project$Search$Main$isLocationEmpty(location) && ($author$project$Shared$Select$isFirstSelected(lengthsOfStay) && $author$project$SearchDatepicker$isNothingSelected(date));
};
var $author$project$Autocomplete$selectedName = function (_v0) {
	var autocomplete = _v0;
	return A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.A;
		},
		autocomplete.Z);
};
var $author$project$LocationFilter$selectedName = function (_v0) {
	var filter = _v0;
	return $author$project$Shared$Input$toValue(filter.z);
};
var $author$project$Search$Main$locationSummaryView = function (_v0) {
	var location = _v0.g;
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('utils:text-truncate')
			]),
		_List_fromArray(
			[
				function () {
				if (!location.a.$) {
					var filter = location.a.a;
					return $elm$html$Html$text(
						$author$project$LocationFilter$selectedName(filter));
				} else {
					var autocomplete = location.a.a;
					return $elm$html$Html$text(
						A2(
							$elm$core$Maybe$withDefault,
							'Any destination',
							$author$project$Autocomplete$selectedName(autocomplete)));
				}
			}()
			]));
};
var $author$project$Search$Main$formSummary = function (model) {
	return $author$project$Search$Main$isEmptyForm(model) ? A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ui-component-text-group variant:pull-left')
			]),
		_List_fromArray(
			[
				$author$project$Shared$Icons$icon('fa-fw fa-search'),
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Search for your dream spa...')
					]))
			])) : A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ui-component-text-group variant:bookends')
			]),
		_List_fromArray(
			[
				$author$project$Shared$Icons$icon('fa-fw fa-search'),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('utils:text-center,text-truncate')
					]),
				_List_fromArray(
					[
						$author$project$Search$Main$locationSummaryView(model),
						$author$project$Search$Main$dateSummaryView(model)
					])),
				$author$project$Shared$Icons$icon('fa-fw fa-times')
			]));
};
var $author$project$Shared$Modal$modalClass = 'ui-component-modal';
var $author$project$Shared$Modal$clickDecoder = F2(
	function (msg, targetClass) {
		return A2($elm$core$String$contains, $author$project$Shared$Modal$modalClass, targetClass) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('');
	});
var $author$project$Shared$Modal$closeIfBackgroundClick = function (closeMsg) {
	return A2(
		$elm$json$Json$Decode$andThen,
		$author$project$Shared$Modal$clickDecoder(closeMsg),
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['target', 'className']),
			$elm$json$Json$Decode$string));
};
var $author$project$Shared$Modal$isOpen = function (state) {
	return !state;
};
var $author$project$Shared$Modal$modalAttributes = function (_v0) {
	var modal = _v0;
	return _List_fromArray(
		[
			A2(
			$author$project$Utils$Html$attributeIf,
			!$author$project$Shared$Modal$isOpen(modal.S),
			A2($elm$html$Html$Attributes$attribute, 'hidden', 'true')),
			A2(
			$author$project$Utils$Html$attributeMaybe,
			$elm$html$Html$Attributes$attribute('aria-describedby'),
			modal.az),
			A2(
			$author$project$Utils$Html$attributeMaybe,
			$elm$html$Html$Attributes$attribute('aria-labelledby'),
			modal.aA),
			$elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2($author$project$Shared$Modal$modalClass, true),
					_Utils_Tuple2('--initial', modal.S === 2)
				])),
			A2($elm$html$Html$Attributes$attribute, 'role', 'dialog'),
			A2($elm$html$Html$Attributes$attribute, 'aria-modal', 'true'),
			A2(
			$elm$html$Html$Events$on,
			'click',
			$author$project$Shared$Modal$closeIfBackgroundClick(modal.at))
		]);
};
var $author$project$Shared$Modal$view = F3(
	function (_v0, attributes, children) {
		var modal = _v0;
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				$author$project$Shared$Modal$modalAttributes(modal),
				attributes),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id(modal.aG),
							$elm$html$Html$Attributes$tabindex(0)
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('ui-component-button variant:dismiss'),
									$elm$html$Html$Events$onClick(modal.at),
									$elm$html$Html$Attributes$type_('button')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Close')
										]))
								])),
						children))
				]));
	});
var $author$project$Search$Main$modalView = function (model) {
	return A3(
		$author$project$Shared$Modal$view,
		model.O,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('reveal:slide-down variant:search')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('h2 utils:loop')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Find your dream spa experience...')
					])),
				$author$project$Search$Main$formView(model)
			]));
};
var $author$project$Search$Main$mobileView = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('search-buttons')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						A2(
							$elm$core$List$cons,
							$elm$html$Html$Events$onClick($author$project$Search$Main$OpenModal),
							$author$project$Ga4$Main$clickAttr(
								_List_fromArray(
									[
										$author$project$Ga4$Main$eventGroup($author$project$Search$Main$ga4EventGroup),
										$author$project$Ga4$Main$clickTarget('Search slideout')
									]))),
						_List_fromArray(
							[
								$author$project$Search$Main$formSummary(model)
							])),
						A2($author$project$Utils$Html$maybeRender, $author$project$Search$Main$filtersLink, model.ab)
					])),
				$author$project$Search$Main$modalView(model)
			]));
};
var $author$project$Search$Main$view = function (model) {
	return $author$project$Search$Main$isHomePage(model) ? $author$project$Search$Main$homeView(model) : ($author$project$Search$Main$isMobile(model) ? $author$project$Search$Main$mobileView(model) : $author$project$Search$Main$desktopView(model));
};
var $author$project$Search$Main$main = $author$project$Utils$Browser$elementThatMightFail(
	{
		bZ: $author$project$Search$Main$decodeFlags,
		b5: $author$project$Search$Main$init,
		bm: $author$project$Shared$Ports$flagDecodingError(0),
		cy: $author$project$Search$Main$subscriptions,
		cF: $author$project$Search$Main$update,
		cH: $author$project$Search$Main$view
	});
_Platform_export({'Search':{'Main':{'init':$author$project$Search$Main$main($elm$json$Json$Decode$value)(0)}}});}(this));